import styled, { css } from 'styled-components';
import Color from '../../../commons/Color';
import Device from '../../../commons/Device';
import OpenSansBold from '../../../commons/mixins/fonts/OpenSansBold';
import OpenSans from '../../../commons/mixins/fonts/OpenSans';
import Title from '../../../components/text/Title';
import { Col } from 'antd';

const Styles = styled.div`
	background-color: #ffffff;
	border-radius: 10px;
	padding: 30px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 20px #00000029;
	margin: 2rem 0;

	/*Items*/

	.ant-row {
		padding-bottom: 2em;
		padding-top: 0.3em;

		@media ${Device.xs} {
			padding-top: 0px;
		}
	}
	.ant-row:last-child {
		padding-bottom: 0em;
	}

	.row-radio {
		@media ${Device.xs} {
			padding-bottom: 0px !important;
		}
		/* padding-bottom: 16px; */
	}

	/*end Items*/

	.ant-col {
		padding-right: 20px;
		line-height: 25.9999px;

		@media ${Device.xs} {
			padding-right: 0px;
		}
	}

	.ant-col:last-child {
		padding-right: 0px;
	}

	.ant-form-item-label label {
		${OpenSansBold(14, 400, Color.text)}
		font-weight: 600;
		margin: 0;
		font-size: 12px !important;
	}

	.ant-radio-wrapper {
		${OpenSansBold(16, 400, Color.footer)};
		font-size: 16px !important;
	}

	.ant-radio-wrapper-checked {
		color: ${Color.main} !important;
	}

	.ant-radio-inner,
	.ant-radio .ant-radio-checked {
		border-color: ${Color.main} !important;
	}

	.ant-radio-checked .ant-radio-inner {
		border-color: ${Color.main} !important;
	}

	.ant-radio-checked .ant-radio-inner::after {
		background-color: ${Color.main};
	}

	/* Pick up location: Esto se va a sacar de aca cuando se haga tal servicio */

	.text-reminder {
		margin-left: 16px;
		margin-bottom: 16px;
		${OpenSans(14, 400, Color.text)}
	}

	.text-reminder-main {
		margin-top: 16px;
		color: ${Color.main};
		font-weight: bold;
		margin-bottom: 0px;
	}

	/* Metting passenger: Esto se va a sacar de aca cuando se haga tal servicio */

	.info {
		padding-left: 23px;
		${OpenSans(14, 400, Color.text)}
		margin-bottom:12px;
		margin-top: 5px;
		b {
			${OpenSansBold(14, 400, Color.text)};
		}
	}

	.info-showed {
		display: block;
	}

	.info-hidden {
		display: none;
	}
`;

export default Styles;

export const StyledTitle = styled(Title)`
	color: ${Color.footer};
	margin: 0px;
	font-weight: 700;
`;

export const StyledCol = styled(Col)`
	padding: 0px 10px;
	line-height: 25.9999px;

	@media ${Device.xs} {
		padding: 0px;
		.ant-form-item-label {
			padding-top: 12px;
			padding-bottom: 5px;
		}
	}
`;

export const StyledContainerLoading = styled.section`
	${props =>
		props.isLoading &&
		css`
			padding-bottom: 60vh;
		`};

	${props =>
		props.error &&
		css`
			min-height: 60vh;
			padding: 0px 30px;
		`};
	@media ${Device.xs} {
		/* min-height: 95vh; */
	}
`;
