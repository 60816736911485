/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import { Form } from 'antd';

import { getTransfers, resetTransferFilter } from '../../actions/searchFormActions';
import SearchForms from '../../../../components/form/SearchForms';
import SearchFormDestinations from '../../../../components/form/SearchForms/SearchFormDestinations';
import SearchFormCurrency from '../../../../components/form/SearchForms/SearchFormCurrency';

const SearchForm = ({
	form,
	header,
	className,
	getTransfers,
	onSubmit,
	resetTransferFilter
}) => {
	const preSearch = !window.location.pathname.includes('search');
	const fetchData = fields => {
		resetTransferFilter();
		getTransfers({
			city_code: fields.city_code,
			date_in: fields.date_in,
			date_out: fields.date_out,
			adults_number: fields.rooms[0].adults_number,
			children_ages: fields.rooms[0].children_ages,
			preset: fields.preset
		});
	};

	return (
		<div className={className}>
			<SearchForms
				form={form}
				header={header}
				destinations={<SearchFormDestinations form={form} md={preSearch ? 5 : 6} />}
				currency={<SearchFormCurrency form={form} md={preSearch ? 3 : 4} />}
				fetchData={fetchData}
				moreRooms={false}
				onSubmit={onSubmit}
				service="transfers"
			/>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		getTransfers: payload => getTransfers(dispatch, payload),
		resetTransferFilter: payload => resetTransferFilter(dispatch, payload)
	};
};

export default connect(
	null,
	mapDispatchToProps
)(Form.create({ name: 'transfer_filter' })(SearchForm));
