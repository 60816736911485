import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReservationPanel from './ReservationPanel';
import ReservationPanelDetail from './ReservationPanelDetail';

/*----- Selectors -----*/
import { hotelRoom } from '../../modules/hotels/selectors/hotelRoomSelector';

/*----- Utils -----*/
import { getMinorRoom } from '../../modules/commons/detail/hotels/HotelRooms/hotelRoomsUtils';

/*----- Actions -----*/
import { selectRoom } from '../../modules/hotels/actions/hotelRoomActions';
import { getId } from '../../modules/commons/checkout/CheckoutUtils';

const ReservationHotelCard = ({
	history,
	showData,
	hotelRoom,
	selectRoom,
	getInformation,
	getDetailCheckout,
	addCheckout,
	panelTitle,
	service
}) => {
	const { t } = useTranslation();
	const isPackage = service === 'packages';
	const defaultRate = getMinorRoom(showData.rates || [showData.rate]);
	let dataInformation = getInformation(
		isPackage ? hotelRoom : showData,
		hotelRoom.item_id,
		isPackage
	);
	let id = getId(history);

	const [amount, setAmount] = useState(defaultRate.amount_with_taxes);

	const [itemsDetails, setItemsDetails] = useState(
		getDetailCheckout(dataInformation, [
			'passengers',
			'nights',
			'regime',
			'roomType'
		])
	);

	const [textButton, setTextButton] = useState(t('general.seeRooms'));
	const [textReserve, setTextReserve] = useState(t('checkout.totalPriceSince'));
	const [selectedRoom, setSelectedRoom] = useState(false);
	useEffect(() => {
		if (!selectedRoom && hotelRoom.amount_with_taxes) {
			setTextButton(t('general.reserve'));
			setItemsDetails(getDetailCheckout(dataInformation));
			setAmount(hotelRoom.amount_with_taxes);
			setTextReserve(t('checkout.totalPrice'));
			setSelectedRoom(true);
		}
	}, [
		defaultRate,
		selectedRoom,
		dataInformation,
		hotelRoom,
		getDetailCheckout,
		getInformation,
		isPackage,
		t
	]);

	return (
		<ReservationPanel
			panelTitle={panelTitle}
			currency={defaultRate.currency}
			amount={amount}
			textTotal={textReserve}
			dateLabel={t('general.date')}
			dateFrom={dataInformation.date_in}
			dateTo={dataInformation.date_out}
			textButton={textButton}
			onClick={() => {
				if (hotelRoom.amount) {
					addCheckout(id, {
						item_id: hotelRoom.item_id
					});
					history.push(`/${service}/${id}/checkout`);
				} else {
					selectRoom();
				}
			}}
		>
			<ReservationPanelDetail items={itemsDetails} />
		</ReservationPanel>
	);
};

const mapStateToProps = state => {
	return {
		hotelRoom: hotelRoom(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		selectRoom: payload => selectRoom(dispatch, payload)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ReservationHotelCard));
