/*----- Imports -----*/
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

/*----- Commons-----*/
import Device from '../../commons/Device';

/*----- Styles-----*/
const StyledNavButton = styled(NavLink)`
	font-size: 14px;
	color: #707070;
	display: flex;
	align-items: center;
	text-decoration: none;
	text-transform: capitalize;
	flex-direction: column;
	margin-right: 32px;
	padding-bottom: 1px;
	justify-content: center;
	max-width: 200px;
	border: none;
	transition: all 0.3s ease-out;
	&:focus {
		outline: none;
	}
	&:hover {
		color: black;
	}

	@media ${Device.xs} {
		min-width: initial;
	}
`;

/*----- Component -----*/
const NavButton = ({ content, destination, className }) => {
	return (
		<StyledNavButton to={destination} className={className}>
			{content}
		</StyledNavButton>
	);
};
export default NavButton;

/*----- PropTypes -----*/
NavButton.propTypes = {
	content: PropTypes.string.isRequired,
	destination: PropTypes.string.isRequired
};
