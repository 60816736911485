export const SET_LOCATION       = "SET_LOCATION"
export const SET_ENTRY_DATE     = "SET_ENTRY_DATE"
export const SET_EXIT_DATE      = "SET_EXIT_DATE"
   
export const ADD_ADULT          = "ADD_ADULT"
export const ADD_YOUNGER        = "ADD_YOUNGER"
export const REMOVE_ADULT       = "REMOVE_ADULT"
export const REMOVE_YOUNGER     = "REMOVE_YOUNGER"
export const INCREMENT_AGE      = "INCREMENT_AGE"
export const DECREMENT_AGE      = "DECREMENT_AGE"
export const ADD_ROOM           = "ADD_ROOM"
export const REMOVE_ROOM        = "REMOVE_ROOM"
export const CHANGE_YOUNGER_AGE = "CHANGE_YOUNGER_AGE"