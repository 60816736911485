import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCol } from './styles';
import { Form } from 'antd';
import { parse } from '../../../utilities/queryString';
import { currencies } from '../../../utilities/constants';
import SearchSelect from '../SearchSelect';
import { withRouter } from 'react-router';

export const driver = window.localStorage;

const { Item } = Form;

const SearchFormCurrency = ({
	history,
	form,
	xs = 24,
	md = 2,
	options = currencies
}) => {
	const { t } = useTranslation();
	const { getFieldDecorator } = form;

	const [initialCurrency, setInitialCurrency] = useState(
		driver.getItem('currency') ? driver.getItem('currency') : 'PESOS'
	);

	useEffect(() => {
		let params = parse(history.location.search, options);

		if (!params) {
			return;
		}
		setInitialCurrency(params.preset);
	}, [history, history.location.search, initialCurrency, options]);

	return (
		<StyledCol xs={xs} md={md}>
			<Item>
				{getFieldDecorator('preset', {
					rules: [{ required: true, whitespace: true }],
					validateTrigger: ['onSubmit'],
					initialValue: initialCurrency
				})(
					<SearchSelect
						name={t('general.currency')}
						options={options}
						onChange={value => {
							driver.setItem('currency', value);
						}}
					/>
				)}
			</Item>
		</StyledCol>
	);
};

export default withRouter(SearchFormCurrency);
