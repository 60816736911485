import styled from 'styled-components';
import { StyledServiceCard } from '../../carousel/styles';

export const StyledServiceRoomCard = styled(StyledServiceCard)`
	height: initial;
	padding: 15px 30px;
	border-radius: 10px;
`;

export const TitleContainer = styled.div`
	padding: 0px 0px 20px 0px;
	border-bottom: 1px solid #66666669;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
