export const validateEmail = email => {
	const re = /^\w+([\.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/; //eslint-disable-line
	return re.test(email);
};
export const validateName = name => {
	const re = /^[a-zA-Z ]{2,30}$/;
	return re.test(name);
};
export const sendEmailSimple = (
	_senderEmail,
	_recipients,
	_subject,
	_header,
	_message,
	_email,
	_name
) => {
	return fetch(process.env.REACT_APP_EMAIL_URL, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Token ${process.env.REACT_APP_EMAIL_TOKEN}`
		},
		mode: 'cors',
		body: JSON.stringify({
			sender: _name,
			sender_email: _senderEmail,
			recipients: [_recipients],
			subject: _subject,
			template_name: 'mensajes-simple',
			template_params: {
				header: _header,
				body: _message
			},
			reply_to: [_email]
		})
	});
};
