import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledServicesCarousel, StyledCarousel, StyledCountText } from './styles';
import RoomCard from '../../components/card/RoomCard';

const ServicesCarouselRoom = ({ className, data, typeRoom, onClick }) => {
	const [item, setItem] = useState(1);
	const { t } = useTranslation();

	const renderData = () => {
		return data.map((item, i) => (
			<RoomCard
				key={i}
				currency={item.currency}
				price={item.rooms_details[0].sale + item.rooms_details[0].iva}
				typeRoom={typeRoom}
				regime={item.regime.name}
				onClick={() => onClick(item)}
			/>
		));
	};

	return (
		<StyledServicesCarousel>
			<StyledCarousel
				className={className}
				dots={false}
				centerMode={true}
				slidesToShow={1}
				infinite={false}
				centerPadding="30px"
				afterChange={current => {
					setItem(current + 1);
				}}
			>
				{renderData()}
			</StyledCarousel>
			<StyledCountText content={`${item} ${t('general.of')} ${data.length}`} bold />
		</StyledServicesCarousel>
	);
};

export default ServicesCarouselRoom;
