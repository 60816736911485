import BASE_URL from '../BaseUrl';

import requestDecorator from '../../decorators/RequestDecorator';
import { stringify } from '../../utilities/queryString';
import i18n from 'i18next';
import { storedCurrency } from '../../utilities/utils';

const HOTELS = 'hotels';

export function* getHotels(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			const result = yield fetch(
				`${BASE_URL}/${HOTELS}/search?${stringify(params)}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': `${i18n.language}`
					}
				}
			);
			return result;
		},
		params,
		cbResponse
	);
}

export function* showHotels(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			const result = yield fetch(
				`${BASE_URL}/${HOTELS}/${params}?preset=${storedCurrency}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': `${i18n.language}`
					}
				}
			);
			return result;
		},
		params,
		cbResponse
	);
}

export function* reserveHotels(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${HOTELS}`, {
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': `${i18n.language}`
				}
			});
		},
		params,
		cbResponse
	);
}
