import styled from 'styled-components';
import DefaultButton from '../button/DefaultButton';
import Text from '../text/Text';
import Color from '../../commons/Color';
import Device from '../../commons/Device';

export const CardContainer = styled.article`
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	background-color: white;
	padding: 24px;

	box-shadow: 0px 8px 20px #00000014;
	border-radius: 10px;
	max-width: 304px;

	@media ${Device.xs} {
		max-width: unset;
		width: 100%;
	}
`;

export const ItemsContainer = styled.div`
	margin-top: 16px;
	width: 100%;
`;

export const Button = styled(DefaultButton)`
	margin-top: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	button {
		min-width: 160px;
	}
`;

export const StyledWarningText = styled(Text)`
	font-size: 14px;
	color: ${Color.main};
	text-align: center;
`;

export const StyledReservationPanel = styled.div`
	height: 100%;
	@media ${Device.xs} {
		width: 90%;
		box-shadow: 0px 3px 20px #00000029;
		margin-top: ${props => props.marginTop};
	}
`;
