/*----- Imports -----*/
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from '../../utilities/utils';

/*----- Components-----*/
import { InputContainer } from './styles';
import Label from './Label';
import { Select, Icon } from 'antd';

const { Option } = Select;

const filterOption = (input, option) =>
	option.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0;

const SearchSelect = (
	{ name, options, onChange, handleChange, placeholder, className, iconName, value },
	ref
) => (
	<InputContainer className={className}>
		<Label name={name} />

		<Select
			ref={ref}
			value={value}
			onChange={onChange || handleChange}
			size="large"
			showSearch={!isMobile()}
			placeholder={placeholder}
			optionFilterProp="children"
			suffixIcon={iconName ? <Icon type={iconName} style={{ color: 'red' }} /> : ''}
			filterOption={filterOption}
		>
			{options.map((option, i) => (
				<Option value={option.value} key={`${i}-${option.value}`}>
					{(option.icon && (
						<img style={{ width: '12px' }} src={option.icon} alt="Ciudad - Hotel" />
					)) ||
						''}
					&nbsp;
					{option.label}
				</Option>
			))}
		</Select>
	</InputContainer>
);

export default forwardRef(SearchSelect);

/*----- PropTypes -----*/
SearchSelect.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	handleChange: PropTypes.func,
	onChange: PropTypes.func
};
