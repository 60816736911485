import React from 'react';
import {
	MainRow,
	StyledCol,
	StyledTitle,
	StyledName,
	StyledTextConfirm
} from './styles';
import { useTranslation } from 'react-i18next';
import ReservationDates from '../../../../components/panel/ReservationDates';
import DetailsItems from '../../checkout/DetailsItems';

const ConfirmDetailsCard = ({ type, name, items, details, dateFrom, dateTo }) => {
	const { t } = useTranslation();

	return (
		<MainRow type="flex">
			<StyledCol xs={24} md={6}>
				<StyledTitle bold content={type} />
				<StyledName bold content={name} />
			</StyledCol>

			<StyledCol xs={24} md={6}>
				<StyledTitle bold content={t('checkout.detail')} />
				{items ? DetailsItems(items) : <StyledTextConfirm content={details} />}
			</StyledCol>

			<StyledCol xs={24} md={6}>
				<StyledTitle bold content={t('checkout.date')} />
				<ReservationDates dateFrom={dateFrom} dateTo={dateTo} />
			</StyledCol>
		</MainRow>
	);
};

export default ConfirmDetailsCard;
