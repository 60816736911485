import { maxAdults, maxRooms } from './constants';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const roomsIsValid = rooms => {
	if (!rooms) {
		return false;
	}

	let roomValid = true;
	Object.keys(rooms).forEach((key, idx) => {
		let idxRooms = [];
		for (let qty = 0; qty < maxRooms; qty++) {
			idxRooms.push(qty.toString());
		}

		if (!idxRooms.find(room => room === key)) {
			roomValid = false;
		}

		let room = rooms[key];
		if (!room.adults_number) {
			roomValid = false;
			return;
		}

		if (
			!Number.isInteger(room.adults_number) ||
			!(room.adults_number >= 1 && room.adults_number <= maxAdults)
		) {
			roomValid = false;
			return;
		}

		/* max childrens : X  */
		if (!room.children_ages || !Array.isArray(room.children_ages)) {
			roomValid = false;
			return;
		}

		room.children_ages.forEach(children => {
			if (!Number.isInteger(children) || !(children >= 0 && children <= 17)) {
				roomValid = false;
				return;
			}
		});
	});

	return roomValid;
};

export const parse = (params, destinations) => {
	try {
		if (!params) return null;

		let objParams = JSON.parse(decodeURIComponent(params.substring(3)));
		let date_in = moment(objParams.date_in, 'YYYY-MM-DD', true);
		let date_out = moment(objParams.date_out, 'YYYY-MM-DD', true);

		if (!date_in.isValid() || !date_out.isValid()) {
			return null;
		}

		if (!objParams.city_code) {
			return null;
		}

		if (!destinations.find(city => city.value === objParams.city_code)) {
			return null;
		}

		if (!roomsIsValid(objParams.rooms)) {
			return null;
		}

		if (objParams.page && !Number.isInteger(objParams.page)) {
			return null;
		}

		/* optionals filters */

		return objParams;
	} catch (e) {
		console.log('ERROR paramsToObject', e);
		return null;
	}
};

export const stringify = params => {
	let queryParams = [];
	let key;
	for (key in params) {
		if (params[key] !== undefined) {
			if (Array.isArray(params[key])) {
				let data = params[key];
				for (let value of data) {
					if (value !== undefined) {
						queryParams.push(
							`${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`
						);
					}
				}
			} else {
				queryParams.push(
					`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
				);
			}
		}
	}
	return queryParams.join('&');
};

export const parseMetadata = (metadata, singleRoom = false) => {
	let newQuery = { ...metadata.query };

	//delete newQuery.preset;

	if (singleRoom) {
		newQuery.rooms = {
			0: {
				adults_number: newQuery.adults_number,
				children_ages: newQuery.children_ages
			}
		};
	}

	delete newQuery.adults_number;
	delete newQuery.children_ages;

	return {
		...metadata,
		query: newQuery
	};
};
