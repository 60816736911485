/*----- Core -----*/
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

/*----- Components -----*/
import CustomModal from '../commons/CustomModal';
import ConsultConfirmationModal from '../commons/ConsultConfirmationModal';
import Title from '../text/Title';
import FormContainer from './FormContainer';

/*----- Commons -----*/
import Color from '../../commons/Color';
import Montserrat from '../../commons/mixins/fonts/Montserrat';

/*----- Antd -----*/
import { Row, Col } from 'antd';

/* Selectors */
import {
	typeConsultSelector,
	nameConsultSelector,
	alertVisibleSelector,
	descriptionConsultSelector
} from '../../modules/app/AppSelectors';
import { toggleModal, toggleAlert } from '../../modules/app/AppActions';
import { resolve } from 'dns';

/* Utils*/
import { sendEmailSimple } from '../../utils/ManagerEmail';

const StyledConsultation = styled.div`
	.consultation-modal__data {
		padding: 32px;
		box-shadow: inset 0 200px 53px -70px #c4fffb;
		.title-light {
			${Montserrat(24, 300, Color.main)};
		}
	}
	.consultation-modal__form-container {
		padding: 32px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.consultation-modal__image-container {
		padding-top: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			max-width: 260px;
			width: 100%;
		}
	}
	/*** Overwrite ***/
	.custom-title,
	.custom-paragraph {
		margin-left: 8px;
	}
	.custom-title {
		margin-bottom: 4px;
	}
	.custom-paragraph {
		margin-top: 0;
	}
	.form-check-label {
		font-family: 'Montserrat', sans-serif;
	}
	.content-icon {
		display: flex;
		justify-content: center;
		margin-bottom: 16px;
	}
`;

const ConsultForm = props => {
	const [alert, setAlert] = useState('');

	const sendEmailConsult = async formValues => {
		let senderEmail = process.env.REACT_APP_EMAIL_SENDER;
		let recipients = process.env.REACT_APP_EMAIL_RECIPIENT;
		let subject = 'Consulta desde EBGroup';
		let header = `<p>Consulta por el ${props.typeConsult}: ${props.nameConsult}</p>`;
		let body = `<p>
				Datos del emisor: <br>
		-   Email: ${formValues.email} <br>
		-   Nombre: ${formValues.name} <br>
		${formValues.phone ? `- Telefono: ${formValues.phone} <br> ` : ''}
		-   Tipo de Cliente: ${formValues.clientType} <br>
		-   Mensaje: <br>
		${formValues.message}
		</p>`;
		const response = await sendEmailSimple(
			senderEmail,
			recipients,
			subject,
			header,
			body,
			formValues.email,
			formValues.name
		);
		if (response.ok) {
			props.toggleModal();
			props.toggleAlert(true);
			setTimeout(function () {
				props.toggleAlert(false);
			}, 5000);
		} else {
			setAlert('Fallo al enviar el formulario');
			setTimeout(function () {
				resolve(setAlert(''));
			}, 2000);
		}
	};

	return (
		<CustomModal className="consultation-modal">
			<StyledConsultation>
				<Row type="flex">
					<Col xs={24} md={12}>
						<Row type="flex" align="top" className="height-100">
							<Col span={24} className="height-100">
								<div className="height-100 d-flex flex-column consultation-modal__data">
									<div className="content-icon">
										<Title
											content={props.nameConsult}
											size={1}
											color={'secondary'}
										/>
									</div>
									<div>
										<p style={{ whiteSpace: 'pre-line' }}>
											{props.descriptionConsult}
										</p>
									</div>
								</div>
							</Col>
						</Row>
					</Col>
					<Col xs={24} md={12} className="consultation-modal__form-container">
						<FormContainer sendEmail={sendEmailConsult} requestError={alert} />
					</Col>
				</Row>
			</StyledConsultation>
			<ConsultConfirmationModal></ConsultConfirmationModal>
		</CustomModal>
	);
};

function mapStateToProps(state) {
	return {
		alertVisible: alertVisibleSelector(state),
		typeConsult: typeConsultSelector(state),
		nameConsult: nameConsultSelector(state),
		descriptionConsult: descriptionConsultSelector(state)
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleModal: (type, name) => toggleModal(dispatch, type, name),
		toggleAlert: state => toggleAlert(dispatch, state)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultForm);
