/* eslint-disable no-undef */
/*----- Core -----*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';

import DataPassengerForm from './DataPassengerForm';

const GetPassengerForm = ({
	form,
	dataCheckout,
	countPassengers,
	passengersRequired
}) => {
	const { t } = useTranslation();
	const passengers = dataCheckout ? dataCheckout.passengers : null;

	let childrenAges = passengersRequired.children_ages;

	return countPassengers.map((passenger, idx) => {
		const isAdult = idx < passengersRequired.adults_number;
		const year = isAdult ? 18 : childrenAges[idx - passengersRequired.adults_number];

		let dataPassenger = passengers ? passengers[idx] : {};
		let passengerLabel = `${t('checkout.passengers.passenger')} ${idx + 1}${
			idx === 0 ? ` (${t('checkout.mainPassenger')})` : ''
		}`;

		let extraText = isAdult ? t('checkout.adult') : `${year} ${t('general.years')}`;

		let defaultPickerValue = Moment();
		let endDate = Moment();
		let initDate = Moment();

		if (isAdult) {
			defaultPickerValue = Moment().subtract(18, 'years');
		} else {
			endDate = isAdult ? null : Moment().subtract(year, 'years').add(1, 'days');
			initDate = isAdult ? null : Moment().subtract(year + 1, 'years');
			defaultPickerValue = initDate;
		}

		return (
			<DataPassengerForm
				key={idx}
				idx={idx}
				form={form}
				isAdult={isAdult}
				endDate={endDate}
				initDate={initDate}
				extraText={extraText}
				dataPassenger={dataPassenger}
				passengerLabel={passengerLabel}
				defaultPickerValue={defaultPickerValue}
			/>
		);
	});
};

export default GetPassengerForm;
