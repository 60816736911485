/*----- Imports -----*/
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
/*----- Commons -----*/
import OpenSansBold from '../../commons/mixins/fonts/OpenSansBold';

/*----- Styles -----*/
const StyledLabel = styled.label`
	${OpenSansBold(10, 400, 'black')}
	color: black;
	font-weight: 600;
	margin: 0;
	margin-bottom: 10px;
	display: block;
	text-transform: capitalize;
	font-size: ${props => props.fontSize};
`;

/*----- Component -----*/
const Label = ({ name, fontSize }) => (
	<StyledLabel fontSize={fontSize} className="custom-label">
		{' '}
		{name}{' '}
	</StyledLabel>
);
export default Label;

/*----- PropTypes -----*/
Label.defaultProptypes = {
	fontSize: '10px'
};

Label.propTypes = {
	name: PropTypes.string,
	fontSize: PropTypes.string
};
