/*----- Core -----*/
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

/*----- Components-----*/
import Label from './Label';
import { InputContainer } from './styles';
import { Input } from 'antd';

/*----- Component -----*/
const DefaultInput = (
	{
		type,
		name,
		placeholder,
		onChange,
		value,
		autofocus,
		suffix,
		prefix,
		className,
		fontSize,
		withoutLabel,
		max,
		min,
		afterChange,
		onPressEnter,
		disabled
	},
	ref
) => (
	<InputContainer className={className}>
		{withoutLabel ? '' : <Label htmlFor={name} name={name} fontSize={fontSize} />}
		<Input
			size="large"
			ref={ref}
			name={name}
			value={value}
			autoFocus={autofocus}
			type={type}
			placeholder={placeholder}
			onChange={value => {
				onChange(value);
				if (afterChange) {
					afterChange(value);
				}
			}}
			onPressEnter={onPressEnter}
			suffix={suffix}
			prefix={prefix}
			max={max}
			min={min}
			disabled={disabled}
		/>
	</InputContainer>
);
export default forwardRef(DefaultInput);

/*----- PropTypes -----*/
Input.propTypes = {
	type: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.any,
	suffix: PropTypes.any,
	prefix: PropTypes.any,
	small: PropTypes.bool,
	autofocus: PropTypes.bool,
	withoutLabel: PropTypes.bool,
	fontSize: PropTypes.string,
	margin: PropTypes.string,
	borderRadius: PropTypes.string,
	minWidth: PropTypes.string,
	afterChange: PropTypes.func,
	disabled: PropTypes.bool
};

DefaultInput.defaultProps = {
	disabled: false
};
