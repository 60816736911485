import React from 'react';
import { ItemCheckoutContainer } from '../../../../../components/containers/CheckoutContainer';
import Text from '../../../../../components/text/Text';
import { useTranslation } from 'react-i18next';
import ArrivalForm from '../../ArrivalForm';

const PackagesArrival = ({ form, withHotel, dataCheckout }) => {
	const { t } = useTranslation();

	return (
		<ItemCheckoutContainer>
			<Text content={t('checkout.packages.transfers')} />
			<ArrivalForm
				form={form}
				dataCheckout={dataCheckout && dataCheckout['in']}
				transferKind="in"
				isMix
			/>
			<ArrivalForm
				form={form}
				dataCheckout={dataCheckout && dataCheckout['out']}
				transferKind="out"
				isMix
			/>
		</ItemCheckoutContainer>
	);
};

export default PackagesArrival;
