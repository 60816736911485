import React from 'react';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import CheckoutConfirmList from '../../../components/list/checkoutConfirmList/CheckoutConfirmList';

const CheckoutPassengersContainer = ({ dataCheckout, passengerLabel }) => {
	const { t } = useTranslation();

	const getPassengerList = () => {
		let passengers = dataCheckout.passengers || [];
		return passengers.map((passenger, idx) => {
			return (
				<CheckoutConfirmList
					title={`${passengerLabel} ${idx + 1}`}
					list={[
						{
							label: t('checkout.passengers.fullname.title'),
							value: `${passenger.name} ${passenger.lastname}`
						},
						{
							label: t('checkout.passengers.nationality.title'),
							value: passenger.nationality
						},
						{
							label: t('checkout.passengers.identification.title'),
							value: passenger.dni_passport
						},
						{
							label: t('checkout.passengers.phone.title'),
							value: passenger.phone
						},
						{
							label: t('checkout.passengers.birthdate.title'),
							value: passenger.date_of_birthday
						}
					]}
					lastItem={idx === passengers.length - 1}
				/>
			);
		});
	};

	return <div>{getPassengerList()}</div>;
};

export default CheckoutPassengersContainer;
