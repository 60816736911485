/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

/*----- Components -----*/
import GenericConfirmCard from '../../../../commons/confirm/GenericConfirm/GenericConfirmCard';
import GenericConfirm from '../../../../commons/confirm/GenericConfirm';

/*----- Utils -----*/
import {
	packagesSteps,
	packagesWithHotelSteps
} from '../../../../../utilities/utils';
import {
	getCheckoutPackages,
	getPackageHotel
} from '../../../../../services/PackagesCheckoutService';
import { getId } from '../../../../commons/checkout/CheckoutUtils';
import { showPackageData } from '../../../selectors/showPackageSelector';

import ConfirmDetailsCard from '../../../../commons/confirm/ConfirmDetailsCard';
import { useTranslation } from 'react-i18next';

import {
	getDetailCheckoutWithHotel,
	getDetailCheckoutWithoutHotel,
	getPackagesInformation
} from '../../checkout/section/detailsItemsPackages';
import TransferInformation from './TransferInformation';
import { getSearchQuery } from '../../../../../services/SearchService';
import { getHotelInformation } from '../../../../hotels/views/checkout/section/detailsItemsHotel';

const SuccessResponse = ({ history, showPackage }) => {
	const { t } = useTranslation();
	const id = getId(history);
	const withHotel = getSearchQuery().with_hotel;
	const dataCheckout = getCheckoutPackages(id);
	const packagesInformation = getPackagesInformation(showPackage, dataCheckout);
	const hotelRoom = getPackageHotel();
	const itemsList = withHotel
		? getDetailCheckoutWithHotel(
				getHotelInformation(hotelRoom, hotelRoom.item_id, true)
		  )
		: getDetailCheckoutWithoutHotel(packagesInformation);

	return (
		<GenericConfirm
			steps={withHotel ? packagesWithHotelSteps : packagesSteps}
			id={id}
			active={3}
			ConfirmCard={
				<GenericConfirmCard
					header={
						<ConfirmDetailsCard
							type={t(`packages.package${withHotel ? 'WithHotel' : 'WithoutHotel'}`)}
							name={packagesInformation.name}
							items={itemsList}
							dateFrom={packagesInformation.date_in}
							dateTo={packagesInformation.date_out}
						/>
					}
					service="packages"
					dataCheckout={dataCheckout}
					taxes={packagesInformation.iva}
					currency={packagesInformation.currency}
					sale={packagesInformation.amount}
				>
					<TransferInformation dataCheckout={dataCheckout} withHotel={withHotel} />
				</GenericConfirmCard>
			}
		/>
	);
};

const mapStateToProps = state => {
	return {
		showPackage: showPackageData(state)
	};
};

export default connect(mapStateToProps, null)(withRouter(SuccessResponse));
