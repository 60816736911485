import React, { useEffect } from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import SearchFormPackages from '../searchForm';
import PackageResultContainer from './PackageResultContainer';

/*----- Selectors -----*/
import { packagesData, packagesIsLoading } from '../../selectors/packagesSelector';

/*----- Utils -----*/
import { parse } from '../../../../utilities/queryString';
import { activitiesDestinations } from '../../../../utilities/constants';

/*----- Actions -----*/
import { getPackages } from '../../actions/searchFormActions';
import { buildPackagesQuery } from '../../../../utilities/buildSearchQuery';
import GenericSearch from '../../../../components/search';

const SearchPackages = ({ isLoading, packages, getPackages, history }) => {
	useEffect(() => {
		let params = parse(history.location.search, activitiesDestinations);

		if (!params) {
			history.push('/packages');
			return;
		}
		getPackages(buildPackagesQuery(params));
	}, [getPackages, history]);

	return (
		<GenericSearch
			isLoading={isLoading}
			data={packages}
			service="packages"
			ResultContainer={<PackageResultContainer />}
			SearchForm={SearchFormPackages}
		/>
	);
};

const mapStateToProps = state => {
	return {
		packages: packagesData(state),
		isLoading: packagesIsLoading(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPackages: payload => getPackages(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPackages);
