import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

/*----- Components -----*/
import { Form } from 'antd';
import { ButtonCheckoutContainer } from '../../../../../components/containers/CheckoutContainer';
import Button from '../../../../../components/button/DefaultButton';

/*----- Selectors -----*/
import { showHotelData } from '../../../selectors/showHotelSelector';

/*----- Service -----*/
import {
	addCheckoutHotel,
	getCheckoutHotel
} from '../../../../../services/HotelCheckoutService';
import {
	loadPassengers,
	countPassengers,
	getId,
	checkoutHandleSubmit,
	passengersFromQuery
} from '../../../../commons/checkout/CheckoutUtils';
import CheckoutPassengers from '../../../../commons/checkout/CheckoutPassengers';
import { getSearchQuery } from '../../../../../services/SearchService';

const HotelForm = ({ history, form, showHotel }) => {
	const { t } = useTranslation();
	let id = getId(history);
	const dataCheckout = getCheckoutHotel(id);
	const searchQuery = getSearchQuery();

	let passengersRequired = passengersFromQuery(searchQuery.rooms);

	const passengers = countPassengers(passengersRequired);

	const setPassengersParsed = data => {
		let checkoutInfo = {
			passengers: [],
			reservation_date_in: searchQuery.date_in,
			reservation_date_out: searchQuery.date_out,
			observation: data.observation || '',
			uuid: showHotel.uuid
		};
		checkoutInfo.passengers = loadPassengers(data, passengers);

		addCheckoutHotel(id, checkoutInfo);
	};

	return (
		<Form
			onSubmit={event => {
				checkoutHandleSubmit(
					event,
					form,
					history,
					'hotels',
					id,
					setPassengersParsed
				);
			}}
		>
			<CheckoutPassengers
				form={form}
				passengersRequired={passengersRequired}
				dataCheckout={dataCheckout}
				countPassengers={passengers}
			/>
			<ButtonCheckoutContainer>
				<Button htmlType="submit" content={t('checkout.confirmReservation')} />
			</ButtonCheckoutContainer>
		</Form>
	);
};

const mapStateToProps = state => {
	return {
		showHotel: showHotelData(state)
	};
};

export default connect(
	mapStateToProps,
	null
)(Form.create({ name: 'checkout_hotel_form' })(withRouter(HotelForm)));
