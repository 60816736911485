import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	StyledDescription,
	StyledText,
	RadioContent,
	StyledSearchSelect,
	StyledItem
} from '../styles';
import { requiredRule } from '../../../../../components/formItems/InputRules';

const MeetingPointRadio = ({ key, form, initialValueSelect, locations }) => {
	const { t } = useTranslation();
	const { getFieldDecorator } = form;

	return (
		<RadioContent>
			<StyledDescription
				content={t('checkout.meetingPassenger.meeting.descriptionMain')}
			/>
			<StyledText
				content={t('checkout.meetingPassenger.meeting.accommodation.label')}
			/>
			<StyledItem>
				{getFieldDecorator('meeting_point', {
					rules: requiredRule(
						t('checkout.meetingPassenger.meeting.accommodation.error')
					),
					validateTrigger: 'onSubmit',
					initialValue: initialValueSelect
				})(
					<StyledSearchSelect
						placeholder={t('checkout.activities.meetingPoint.field.title')}
						options={locations}
					/>
				)}
			</StyledItem>
		</RadioContent>
	);
};

export default MeetingPointRadio;
