import styled from 'styled-components';
import Device from '../../commons/Device';

export default styled.main`
	.featured-container {
		margin-top: 50px;
		margin-bottom: 50px;
	}

	.featured-card__title {
		margin-top: 50px;
		margin-bottom: 0px;
		margin-left: 80px;
	}

	.card__list-container {
		padding-top: 10px;
		margin: 5px 80px;
	}

	.filter-form {
		margin-top: -64px;
	}

	.card-container {
		padding: 10px;
	}

	main {
		padding-top: 5px;
		.ant-row {
			margin-top: 10px;
		}
	}

	@media ${Device.xs} {
		display: flex;
		flex-direction: column;
		.card__list-container {
			padding-top: 10px;
			margin: 15px;
		}

		.card-container {
			padding: 0;
		}

		.featured-card__title {
			margin: 10px;
		}

		.filter-form {
			margin-top: -200px;
		}
	}
`;

export const CarouselContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 50px;
`;
