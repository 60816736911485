import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import PackageDetail from '../../../modules/packages/views/detail';
import SaleCard from '../SaleCard';
import { TitleContainer, StyledTitle, StyledGenericModal } from './styles';
import SeeDetail from '../SaleCard/SeeDetail';
import { isMobile } from '../../../utilities/utils';
import { addPackageRates } from '../../../services/PackagesCheckoutService';

const PackageSaleCard = ({
	history,
	title,
	description,
	itemId,
	withHotel,
	rates,
	hotelId,
	...others
}) => {
	const [visible, setVisible] = useState(false);
	const { t } = useTranslation();
	const selectPackageHandler = () => {
		addPackageRates(rates);
		history.push(`/packages/${itemId}${withHotel ? `/hotels/${hotelId}` : ''}`);
	};
	return (
		<>
			<SaleCard
				{...others}
				titlePrice={
					withHotel ? t('checkout.totalPriceSince') : t('checkout.totalPrice')
				}
				action={withHotel ? 'packages.goToHotel' : 'general.seeDetail'}
				onClick={() => selectPackageHandler()}
				cardInformation={
					<>
						<TitleContainer>
							<StyledTitle
								bold
								content={title}
								marginBottom={withHotel ? '5px' : '16px'}
							/>
						</TitleContainer>
						{withHotel && (
							<SeeDetail
								handlerClick={() => {
									if (isMobile() && withHotel) {
										history.push(`/packages/${itemId}`);
									} else {
										setVisible(true);
									}
								}}
								text={t('general.seeDetail')}
							/>
						)}
					</>
				}
			/>

			<StyledGenericModal
				visible={visible}
				onCancel={() => setVisible(false)}
				content={<PackageDetail />}
			/>
		</>
	);
};

export default withRouter(PackageSaleCard);
