/*----- Core -----*/
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

/*----- Components-----*/
import Label from '../form/Label';
import { InputContainer } from '../form/styles';
import { DatePicker } from 'antd';
import { DateItem } from './styles';

import { handleDateRender, handleEnableAllDates } from './Handlers';

import moment from 'moment';
import 'moment/locale/es';
import { isMobile } from '../../utilities/utils';

moment.locale('es');
const dateFormats = ['DD/MM/YYYY', 'DD-MM-YYYY'];

/*----- Component -----*/
const CustomDatePicker = (
	{
		name,
		onChange,
		placeholder = name,
		funcDisabledDate = handleEnableAllDates,
		onOpenChange,
		value,
		afterChange,
		defaultPickerValue = moment(),
		withoutLabel,
		className,
		fontSizePlaceHolder,
		...props //include open
	},
	ref
) => {
	return (
		<InputContainer className={className} fontSizePlaceHolder={fontSizePlaceHolder}>
			{withoutLabel ? '' : <Label htmlFor={name} name={name} />}
			<DatePicker
				value={value}
				onChange={(date, dateString) => {
					onChange(date, dateString);
					if (afterChange) {
						afterChange(date, dateString);
					}
				}}
				size="large"
				placeholder={placeholder}
				disabledDate={current => funcDisabledDate(current)}
				dateRender={current => handleDateRender(current, DateItem)}
				format={dateFormats}
				onOpenChange={onOpenChange}
				showToday={false}
				defaultPickerValue={defaultPickerValue}
				ref={ref}
				{...props}
				inputReadOnly={!isMobile()}
			/>
		</InputContainer>
	);
};

export default forwardRef(CustomDatePicker);

/*----- PropTypes -----*/
CustomDatePicker.propTypes = {
	onChange: PropTypes.func
};
