/*----- Core -----*/
import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import StepsContainer from '../../../../../components/steps/StepsContainer';
import {
	ItemCheckoutContainer,
	CheckoutContainer
} from '../../../../../components/containers/CheckoutContainer';
import CardCheckoutData from '../../../../commons/checkout/CardCheckoutData';
import CheckoutInfo from '../../../../commons/checkout/CheckoutInfo';
import HotelForm from './HotelForm';
import { getId } from '../../../../commons/checkout/CheckoutUtils';
import { showHotelData } from '../../../selectors/showHotelSelector';
import { StyledTitleCheckout } from '../../../../commons/checkout/CheckoutInfo/styles';
import { getHotelInformation, getDetailCheckout } from './detailsItemsHotel';

/*----- Utils -----*/
import { hotelSteps } from '../../../../../utilities/utils';
import { getCheckoutHotel } from '../../../../../services/HotelCheckoutService';

/*----- Assets -----*/
import DefaultImage from '../../../../../assets/images/DefaultCardImage.svg';

const SuccessResponse = ({ history, showHotel }) => {
	const { t } = useTranslation();
	const dataCheckout = getCheckoutHotel(getId(history));
	const hotelInformation = getHotelInformation(showHotel, dataCheckout.item_id);

	return (
		<CheckoutContainer>
			<StepsContainer
				active={2}
				rate_id={getId(history)}
				dataSteps={hotelSteps(getId(history))}
			/>

			<ItemCheckoutContainer>
				<CheckoutInfo
					header={<StyledTitleCheckout bold content={showHotel.name} />}
					CardCheckoutData={
						<CardCheckoutData
							name={hotelInformation.roomType}
							dateFrom={hotelInformation.date_in}
							dateTo={hotelInformation.date_out}
							totalPrice={hotelInformation.amount}
							image={showHotel.images[0] || DefaultImage}
							currency={hotelInformation.currency}
							items={getDetailCheckout(hotelInformation, ['regime', 'passengers'])}
							type={t('hotels.room')}
						/>
					}
				/>
			</ItemCheckoutContainer>
			<HotelForm />
		</CheckoutContainer>
	);
};

const mapStateToProps = state => {
	return {
		showHotel: showHotelData(state)
	};
};

export default connect(mapStateToProps, null)(withRouter(SuccessResponse));
