import BASE_URL from '../BaseUrl';
import requestDecorator from '../../decorators/RequestDecorator';
import { stringify } from '../../utilities/queryString';
import { storedCurrency } from '../../utilities/utils';

const ACTIVITIES = 'activities';

export function* getActivities(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${ACTIVITIES}/search?${stringify(params)}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': 'en'
				}
			});
		},
		params,
		cbResponse
	);
}

export function* showActivities(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(
				`${BASE_URL}/${ACTIVITIES}/${params}?preset=${storedCurrency}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': 'en'
					}
				}
			);
		},
		params,
		cbResponse
	);
}

export function* reserveActivity(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${ACTIVITIES}`, {
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': 'en'
				}
			});
		},
		params,
		cbResponse
	);
}
