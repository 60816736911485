import React from 'react';
import { ItemCheckoutContainer } from '../../../../../components/containers/CheckoutContainer';
import Text from '../../../../../components/text/Text';
import { useTranslation } from 'react-i18next';
import PackagesLodgingForm from './PackagesLodgingForm';

const PackagesLodging = ({ form, dataCheckout, locations }) => {
	const { t } = useTranslation();

	return (
		<ItemCheckoutContainer>
			<Text content={t('checkout.packages.lodging')} />
			<PackagesLodgingForm
				form={form}
				dataCheckout={dataCheckout}
				locations={locations}
			/>
		</ItemCheckoutContainer>
	);
};

export default PackagesLodging;
