import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import es from './locales/es';
import br from './locales/br';

const resources = {
	es: {
		translation: es
	},
	'pt-BR': {
		translation: br
	}
};

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources,
		fallbackLng: 'es',
		interpolation: {
			escapeValue: false
		},
		whitelist: ['es', 'pt-BR']
	});

if (navigator.language.includes('pt')) {
	//pt-PT, pt-BR, pt
	i18n.changeLanguage('pt-BR');
} else {
	i18n.changeLanguage('es');
}
export default i18n;
