import React from 'react';
import ViewsContainer from './modules/commons/ViewsContainer';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { store, history } from './store/Store';
import GlobalStyle from './GlobalStyle';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const App = () => (
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<ViewsContainer />
			<GlobalStyle />
		</ConnectedRouter>
	</Provider>
);

export default App;
