/*----- Core -----*/
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

/*----- Components -----*/
import { Form } from 'antd';
import { getPackages, resetPackagesFilter } from '../../actions/searchFormActions';
import SearchFormDestinations from '../../../../components/form/SearchForms/SearchFormDestinations';
import SearchFormNights from '../../../../components/form/SearchForms/SearchFormNights';
import { buildPackagesQuery } from '../../../../utilities/buildSearchQuery';
import SearchFormWithHotel from '../../../../components/form/SearchForms/SearchFormWithHotel';
import { StyledSearchForm } from './styles';
import { addWithHotel, getWithHotel } from '../../../../services/SearchService';
import SearchFormCurrency from '../../../../components/form/SearchForms/SearchFormCurrency';

const SearchForm = ({
	history,
	form,
	header,
	getPackages,
	onSubmit,
	resetPackagesFilter
}) => {
	const [withHotel, setWithHotel] = useState(getWithHotel());
	const preSearch = !window.location.pathname.includes('search');
	const fetchData = fields => {
		resetPackagesFilter();
		getPackages(buildPackagesQuery(fields));
	};

	const handleSwitchChange = value => {
		setWithHotel(value, true);
		addWithHotel(value);
	};

	return (
		<StyledSearchForm
			options={
				<SearchFormWithHotel
					form={form}
					afterChange={val => handleSwitchChange(val)}
				/>
			}
			form={form}
			header={header}
			destinations={<SearchFormDestinations form={form} md={preSearch ? 3 : 4} />}
			nights={<SearchFormNights form={form} md={preSearch ? 2 : 3} />}
			currency={<SearchFormCurrency form={form} md={preSearch ? 3 : 4} />}
			fetchData={fetchData}
			moreRooms={withHotel}
			onSubmit={onSubmit}
			service="packages"
			className="search-form-package"
		/>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		getPackages: payload => getPackages(dispatch, payload),
		resetPackagesFilter: payload => resetPackagesFilter(dispatch, payload)
	};
};

export default withRouter(
	connect(
		null,
		mapDispatchToProps
	)(Form.create({ name: 'packages_filter' })(SearchForm))
);
