function* requestDecorator(fetch_function, params, cbResponse) {
	let response = yield fetch_function(params);
	let body;

	switch (response.status) {
		case 200:
			body = yield response.json();
			cbResponse.onOk
				? yield cbResponse.onOk(body)
				: yield cbResponse.onSuccess(body);
			break;

		case 206:
			//wait
			body = yield response.json();
			cbResponse.onPartialContent
				? yield cbResponse.onPartialContent(body)
				: yield cbResponse.onSuccess(body);

			yield requestDecorator(fetch_function, params, cbResponse);
			break;

		case 400:
			cbResponse.onBadRequest
				? yield cbResponse.onBadRequest()
				: yield cbResponse.onError('400 Bad request');
			break;

		case 401:
			cbResponse.onUnauthorized
				? yield cbResponse.onUnauthorized()
				: yield cbResponse.onError('401 Unauthorized');
			break;

		case 404:
			cbResponse.onNotFound
				? yield cbResponse.onNotFound()
				: yield cbResponse.onError('404 Not found');
			break;

		case 424:
			cbResponse.onFailedDependency
				? yield cbResponse.onFailedDependency()
				: yield cbResponse.onError('424 Failed Dependency');
			break;

		case 500:
			cbResponse.onNotFound
				? yield cbResponse.onInternalServerError()
				: yield cbResponse.onError('500 Error');
			break;

		default:
			break;
	}
}

export default requestDecorator;
