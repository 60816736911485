import TransfersIcon from '../../../../../assets/icons/checkout/transfer.svg';
import PersonIcon from '../../../../../assets/icons/custom_icons/user.svg';
import { getPassengerInformation } from '../../../../hotels/views/checkout/section/detailsItemsHotel';

const detailsItemsPackages = withHotel => {
	return [
		{ icon: TransfersIcon, text: 'Traslados' },
		{
			icon: PersonIcon,
			text: withHotel
				? getPassengerInformation().passengers
				: getPassengerInformation().passengersText
		}
	];
};

export default detailsItemsPackages;
