import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../../utilities/utils';

/*----- Components -----*/
import {
	StyledText,
	StyledTitle,
	DescriptionContainer,
	StyledIconArrow,
	ArrowDiv,
	StyledArrowText
} from './styles';

const ActivityDescription = ({ title = 'general.knowMore', description }) => {
	const { t } = useTranslation();
	const [showShortDescription, setShowShortDescription] = useState(isMobile());

	let substring = description.substring(0, 445).split(' ');
	let shortDescription = `${substring.slice(0, substring.length - 1).join(' ')} ...`;

	return (
		<DescriptionContainer>
			<StyledTitle content={t(title)} size={3} />
			<StyledText content={showShortDescription ? shortDescription : description} />
			{isMobile() && (
				<ArrowDiv>
					<StyledArrowText bold content={t('general.seeMore')} />
					<StyledIconArrow
						type={showShortDescription ? 'down' : 'up'}
						onClick={() => setShowShortDescription(!showShortDescription)}
					/>
				</ArrowDiv>
			)}
		</DescriptionContainer>
	);
};

export default ActivityDescription;
