import React, { useEffect } from 'react';

/*----- Components -----*/
import { StyledRow, StyledDetailCol, StyledReservationCol } from '../styles';
import DetailHeader from '../DetailHeader';
import StepsContainer from '../../../../components/steps/StepsContainer';
import HotelTitle from './HotelTitle';
import HotelRooms from './HotelRooms';
import HotelRoomsMobile from './HotelRoomsMobile';
import DetailDescription from '../DetailDescription';
import DetailPackageRoom from '../DetailPackageRoom';
import { Col } from 'antd';

/*----- Utils -----*/
import { isMobile } from '../../../../utilities/utils';
import { connect } from 'react-redux';
import { hotelRoom } from '../../../hotels/selectors/hotelRoomSelector';
import ReservationHotelCard from '../../../../components/panel/ReservationHotelCard';
import { parsePackageHotel } from '../../checkout/CheckoutUtils';

const GenericDetailContainer = ({
	id,
	showData,
	hotelRoom,
	setHotelRoom,
	addCheckout,
	getInformation,
	getCheckout,
	getDetailCheckout,
	active,
	dataStep,
	panelTitle,
	service
}) => {
	const isPackage = service === 'packages';
	const hotelData = isPackage ? parsePackageHotel(showData) : showData;
	useEffect(() => {
		let checkout = getCheckout(id);
		if (
			!isPackage &&
			checkout &&
			checkout.item_id &&
			Object.keys(hotelRoom).length === 0
		) {
			const rate = showData.rates.find(rate => checkout.item_id === rate.item_id);
			setHotelRoom(rate);
		}
	}, [getCheckout, hotelRoom, id, service, setHotelRoom, showData, isPackage]);

	return (
		<>
			<StepsContainer active={active} rate_id={id} dataSteps={dataStep(id)} />
			<DetailHeader images={hotelData.images} />
			<StyledRow type="flex">
				<StyledDetailCol xs={24} md={17} lg={18}>
					<HotelTitle showData={hotelData} />
					<DetailDescription
						description={hotelData.info.observations[0].value || ''}
					/>
					{isPackage && <DetailPackageRoom data={hotelRoom} />}
					{!isMobile() && !isPackage && (
						<HotelRooms
							showData={showData}
							getInformation={getInformation}
							getDetailCheckout={getDetailCheckout}
						/>
					)}
				</StyledDetailCol>
				{isMobile() && !isPackage && (
					<Col span={24}>
						<HotelRoomsMobile
							service={service}
							showData={showData}
							addCheckout={addCheckout}
							getInformation={getInformation}
							getDetailCheckout={getDetailCheckout}
						/>
					</Col>
				)}
				{!isMobile() && (
					<StyledReservationCol xs={24} md={7} lg={6}>
						<ReservationHotelCard
							service={service}
							panelTitle={panelTitle}
							showData={showData}
							addCheckout={addCheckout}
							getInformation={getInformation}
							getDetailCheckout={getDetailCheckout}
						/>
					</StyledReservationCol>
				)}
			</StyledRow>
		</>
	);
};

const mapStateToProps = state => {
	return {
		hotelRoom: hotelRoom(state)
	};
};

export default connect(mapStateToProps, null)(GenericDetailContainer);
