import React from 'react';
import { StyledLogo, StyledHeaderItems, StyledColOptions } from './styles';
import logo from '../../assets/logoTrinus.png';
import SelectLanguage from '../commons/nav/Header/SelectLanguage';
import { Link } from 'react-router-dom';

const HeaderItems = () => {
	return (
		<StyledHeaderItems>
			<Link to="/home">
				<StyledLogo src={logo} alt="logo" />
			</Link>
			<StyledColOptions>
				<SelectLanguage withoutText />
			</StyledColOptions>
		</StyledHeaderItems>
	);
};

export default HeaderItems;
