import styled from 'styled-components';
import { Col } from 'antd';
import Device from '../../../../commons/Device';

export const StyledReservationSuccess = styled.div`
	background-color: white;
	padding-top: 30px;
	padding-bottom: 100px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;

	& > div {
		width: 50%;
	}

	@media ${Device.xs} {
		padding: 0px;
		margin-bottom: 20%;
		& > div {
			width: 100%;
		}
	}
`;

export const ImageReservation = styled(Col)`
	height: 380px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	@media ${Device.xs} {
		margin: 0px 25px;
		height: 250px;
	}
`;
