/*----- Core -----*/
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

/*----- Commons -----*/
import Montserrat from '../commons/mixins/fonts/Montserrat'
import Device     from '../commons/Device'

/*----- Styles-----*/
const StyledContactItem = styled.article`
	padding: 8px 0;
	@media ${Device.sm} {
	}
	@media ${Device.md} {
	}
	.contact-item__text-container {
	}
	.contact-item__title {
		${ Montserrat(10, 600, 'grey') };
	}
	.contact-item__data {
		${ Montserrat(16, 400, 'grey') };
	}
	@media ${Device.md} {
	}
	@media ${Device.lg} {
	}
`/*----- Component -----*/
const ContactItem = ({ title, data }) => (
  <StyledContactItem className="contact-item">
		<div className="contact-item__text-container">
			<div className="contact-item__title">
				{ title }
			</div>
			<div className="contact-item__data">
				{ data }
			</div>
		</div>
  </StyledContactItem>
)
export default ContactItem

/*----- PropTypes -----*/
ContactItem.propTypes = {
  title: PropTypes.string.isRequired,
  data:  PropTypes.string.isRequired
}
