import React from 'react';
import {
	CheckoutDataImage,
	MainRow,
	StyledCol,
	StyledLastCol,
	StyledTitle,
	StyledName,
	StyledPriceText,
	StyledPriceTaxes,
	StyledTextCheckout
} from './styles';
import ReservationDates from '../../../../components/panel/ReservationDates';

import { useTranslation } from 'react-i18next';
import DetailsItems from '../DetailsItems';

const CardCheckoutData = ({
	image,
	type,
	name,
	dateFrom,
	dateTo,
	currency,
	totalPrice,
	items,
	details
}) => {
	const { t } = useTranslation();

	const colMd = image ? 5 : 6;
	return (
		<MainRow type="flex" withimage={image ? true : false}>
			{image && <CheckoutDataImage xs={24} md={colMd} src={image} />}

			<StyledCol xs={24} md={colMd}>
				<StyledTitle bold content={type} />
				<StyledName bold content={name} />
			</StyledCol>

			<StyledCol xs={24} md={colMd}>
				<StyledTitle bold content={t('checkout.detail')} />
				{items ? DetailsItems(items) : <StyledTextCheckout content={details} />}
			</StyledCol>

			<StyledCol xs={24} md={colMd}>
				<StyledTitle bold content={t('checkout.date')} />
				<ReservationDates dateFrom={dateFrom} dateTo={dateTo} />
			</StyledCol>

			<StyledLastCol xs={24} md={4}>
				<StyledTitle bold content={t('checkout.totalPrice')} />
				<StyledPriceText content={`${currency} ${totalPrice.toFixed(2)}`} />
				<StyledPriceTaxes content={t('checkout.taxes')} />
			</StyledLastCol>
		</MainRow>
	);
};

export default CardCheckoutData;
