/*----- Core -----*/
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/*----- Assets -----*/
import phone_icon from '../../assets/contact/phone_icon.svg';
import email_icon from '../../assets/contact/email_icon.svg';
import BrazilIcon from '../../assets/icons/brazil.svg';
import ArgentinaIcon from '../../assets/icons/argentina.svg';
/*----- Ant design -----*/
import { Row, Col } from 'antd';

/*----- Components -----*/
import HertoText from '../../components/hero/HeroText';
import ContactItem from '../../components/ContactItem';

/*----- Styles -----*/
import Styles from './ContactStyles';

/*----- Sections -----*/
import ContactForm from './section/ContactForm';

import { toggleFooter } from '../../modules/app/AppActions';

/*----- Export -----*/
const Contact = props => {
	const { t } = useTranslation();

	useEffect(() => {
		props.toggleFooter();
		return function cleanup() {
			props.toggleFooter();
		};
	});
	return (
		<Styles>
			<Col
				xs={24}
				sm={{ span: 24 }}
				md={{ span: 20, offset: 2 }}
				lg={{ span: 22, offset: 1 }}
				xl={{ span: 22, offset: 1 }}
			>
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<Row type="flex" justify="start" align="top">
						<HertoText title={t('contact.title')} subtitle={t('contact.subtitle')} />
					</Row>
					<Row type="flex" justify="start" align="top">
						<Col
							xs={0}
							sm={0}
							md={0}
							lg={12}
							xl={12}
							className="contact-items-container"
						>
							<img
								src={phone_icon}
								alt="phone icon"
								className="contact-items-icon"
							/>
							<div>
								<div className="contact-item">
									<img src={ArgentinaIcon} alt="Argentina Icon"></img>
									<p>+ 54 3757 422-681</p>
								</div>
								<div className="contact-item">
									<img src={BrazilIcon} alt="Brazil Icon"></img>
									<p>+ 55 45 3027-7722</p>
								</div>
								<div className="contact-item">
									<img src={BrazilIcon} alt="Brazil Icon"></img>
									<p>+ 55 45 99133-4845</p>
								</div>
							</div>
						</Col>
						<Col
							xs={0}
							sm={0}
							md={0}
							lg={12}
							xl={12}
							className="contact-items-container"
						>
							<img
								src={email_icon}
								alt="email icon"
								className="contact-items-icon"
							/>
							<ContactItem
								title={t('contact.attention')}
								data="consultas@ebgrouptravel.com"
							/>
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<Row type="flex" justify="center" align="middle">
						<ContactForm />
					</Row>
				</Col>
				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<Row type="flex" justify="start" align="top">
						<Col
							xs={24}
							sm={12}
							md={12}
							lg={0}
							xl={0}
							className="contact-items-container"
						>
							<img
								src={phone_icon}
								alt="phone icon"
								className="contact-items-icon"
							/>
							<ContactItem title="línea telefónica" data="+ 54 3757 422-681" />
							<ContactItem title="línea telefónica" data="+ 55 45 3027-7722" />
							<ContactItem title="línea telefónica" data="+ 55 45 99133-4845" />
						</Col>
						<Col
							xs={24}
							sm={12}
							md={12}
							lg={0}
							xl={0}
							className="contact-items-container"
						>
							<img
								src={email_icon}
								alt="email icon"
								className="contact-items-icon"
							/>
							<ContactItem
								title="atención general"
								data="consultas@ebgrouptravel.com"
							/>
						</Col>
					</Row>
				</Col>
			</Col>
		</Styles>
	);
};
function mapStateToProps(state) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleFooter: () => toggleFooter(dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
