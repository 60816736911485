import React from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import HotelSaleCard from '../../../../components/card/HotelSaleCard';

/*----- Selectors -----*/
import { hotelsData } from '../../selectors/hotelsSelector';

const ResultList = ({ hotelsData }) => {
	return hotelsData.map((data, i) => {
		return data.rates.map((rate, i) => (
			<HotelSaleCard
				key={i}
				title={data.name}
				imageUrl={data.images[0]}
				stars={data.info.star}
				regime={rate.regime.name}
				price={rate.amount}
				currency={rate.currency}
				destination={`/hotels/${data.uuid}`}
			/>
		));
	});
};

const mapStateToProps = state => {
	return {
		hotelsData: hotelsData(state)
	};
};

export default connect(mapStateToProps, null)(ResultList);
