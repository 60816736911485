import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Device from '../../../../../commons/Device';

/*----- Components -----*/
import { Col, Row } from 'antd';
import CheckoutDataItem, {
	DataTitle,
	DataDescription
} from '../../../../commons/checkout/CheckoutDataItem';

const StyledRow = styled(Row)`
	width: 100%;
	.ant-col {
		@media ${Device.xs} {
			padding: 12px 0px;
			&:first-child {
				padding-top: 0px;
			}

			&:last-child {
				padding-bottom: 0px;
			}
		}
	}
`;

const CheckoutConfirmHeader = ({ type, title, dateIn, dateOut }) => {
	const { t } = useTranslation();
	const date = () => {
		if (dateIn) {
			if (dateOut) {
				return `${dateIn} - ${dateOut}`;
			} else {
				return dateIn;
			}
		}
		return dateOut;
	};
	return (
		<StyledRow type="flex">
			<Col xs={24} md={6}>
				<CheckoutDataItem label={t('transfers.typeOfTransfer')} isConfirm={true}>
					<DataTitle>{type}</DataTitle>
				</CheckoutDataItem>
			</Col>

			<Col xs={24} md={6}>
				<CheckoutDataItem label={t('checkout.detail')} isConfirm={true}>
					<DataDescription>{title}</DataDescription>
				</CheckoutDataItem>
			</Col>

			<Col xs={24} md={6}>
				<CheckoutDataItem label={t('general.date')} isConfirm={true}>
					<DataDescription>{date()}</DataDescription>
				</CheckoutDataItem>
			</Col>
		</StyledRow>
	);
};

export default CheckoutConfirmHeader;

/*----- PropTypes -----*/
CheckoutConfirmHeader.propTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	dateIn: PropTypes.string,
	dateOut: PropTypes.string
};
