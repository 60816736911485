import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	StyledOtherPointText,
	RadioContent,
	Fields,
	OtherPointItem
} from '../styles';
import { StyledInput } from '../../../../../components/form/styles';
import { StyledCol } from '../../styles';

const OtherPointRadio = ({ form, initialHotel, initialAddress }) => {
	const { t } = useTranslation();
	const { getFieldDecorator } = form;

	return (
		<RadioContent>
			<StyledOtherPointText
				content={t('checkout.meetingPassenger.other.descriptionMain')}
			/>
			<StyledOtherPointText
				bold
				red
				content={t('checkout.meetingPassenger.other.description')}
			/>
			<Fields>
				<StyledCol xs={24} sm={12} md={12}>
					<OtherPointItem
						label={t('checkout.meetingPassenger.other.accommodation.title')}
					>
						{getFieldDecorator(`otherPointHotel`, {
							rules: [
								{
									required: true,
									message: t('checkout.meetingPassenger.other.accommodation.error')
								}
							],
							initialValue: initialHotel
						})(
							<StyledInput
								type="text"
								placeholder={t(
									'checkout.meetingPassenger.other.accommodation.label'
								)}
							/>
						)}
					</OtherPointItem>
				</StyledCol>
				<StyledCol xs={24} sm={12} md={12}>
					<OtherPointItem label={t('checkout.meetingPassenger.other.address.title')}>
						{getFieldDecorator(`otherPointAddress`, {
							rules: [
								{
									required: true,
									message: t('checkout.meetingPassenger.other.address.error')
								}
							],
							initialValue: initialAddress
						})(
							<StyledInput
								type="text"
								placeholder={t('checkout.meetingPassenger.other.address.label')}
							/>
						)}
					</OtherPointItem>
				</StyledCol>
			</Fields>
		</RadioContent>
	);
};

export default OtherPointRadio;
