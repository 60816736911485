import styled from 'styled-components';

/*----- Commons -----*/
import Device from '../../../commons/Device';

export const ArticleCenter = styled.article`
	display: flex;
	justify-content: center;
`;

export const SearchContainer = styled.div`
	z-index: 1;
	width: 1700px;
	border-radius: 8px;
	box-shadow: 0px 10px 60px -10px rgba(0, 0, 0, 0.5);
	background-color: white;
	margin-top: -64px;

	@media ${Device.xs} {
		width: 90%;
		padding: 30px 15px;
		margin-top: ${props => props.marginTopOffset};

		.custom-title {
			text-align: center;
			font-size: 18px;
			font-weight: bold;
			margin: 0px;
		}
	}
`;
