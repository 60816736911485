import React from 'react';
import {
	StyledContact,
	StyledContactList,
	StyledContactItem,
	StyledContactIcon,
	StyledContactTitle,
	StyledContactText
} from './styles';
import { useTranslation } from 'react-i18next';
import BrazilIcon from '../../../../assets/icons/brazilian-flag.svg';
import ArgentinaIcon from '../../../../assets/icons/argentinian-flag.svg';

const Item = ({ icon, number, alt }) => (
	<StyledContactItem>
		<StyledContactIcon src={icon} alt={alt} />
		<StyledContactText content={number} />
	</StyledContactItem>
);

const Contact = () => {
	const { t } = useTranslation();

	return (
		<StyledContact>
			<StyledContactTitle content={t('general.footer.contactUs')} />
			<StyledContactList>
				<Item icon={ArgentinaIcon} alt="Argentina Icon" number="+ 54 3757 422-681" />
				<Item icon={BrazilIcon} alt="Brazil Icon" number="+ 55 45 3027-7722" />
				<Item icon={BrazilIcon} alt="Brazil Icon" number="+ 55 45 99133-4845" />
			</StyledContactList>
		</StyledContact>
	);
};

export default Contact;
