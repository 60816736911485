import React from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { StyledHeader, StyledContent, StyledMenu } from './styles';
import MinNavButton from '../../../button/MinNavButton';
import ServicesNavBar from './ServicesNavBar';
import SelectLanguage from './SelectLanguage';

const Header = () => {
	const { t } = useTranslation();

	return (
		<StyledHeader>
			<StyledContent>
				<StyledMenu>
					<MinNavButton content={t('general.menu.init')} destination="/home" />
					<MinNavButton
						content={t('general.menu.contactUs')}
						destination="/contact"
					/>
					<MinNavButton
						content={t('general.menu.aboutUs')}
						destination="/about_us"
					/>
					<SelectLanguage />
				</StyledMenu>
			</StyledContent>
			<ServicesNavBar />
		</StyledHeader>
	);
};

export default withRouter(Header);
