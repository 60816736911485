import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { FormRowSwitch } from './styles';
import { withRouter } from 'react-router';
import DefaultSwitch from '../Switch';
import { getWithHotel } from '../../../services/SearchService';

const SearchFormWithHotel = ({ history, form, afterChange }) => {
	const { t } = useTranslation();
	const withHotel = getWithHotel();
	const getLabel = hotel =>
		hotel ? t('packages.withHotel') : t('packages.withoutHotel');

	const [label, setLabel] = useState(getLabel(withHotel));

	return (
		<FormRowSwitch
			type="flex"
			display={history.location.pathname !== '/packages/search' ? true : false}
		>
			<Form.Item>
				{form.getFieldDecorator('withHotel', {
					valuePropName: 'checked',
					initialValue: withHotel
				})(
					<DefaultSwitch
						afterChange={value => {
							setLabel(getLabel(value));
							afterChange(value);
						}}
						label={label}
					/>
				)}
			</Form.Item>
		</FormRowSwitch>
	);
};

export default withRouter(SearchFormWithHotel);
