import styled from 'styled-components';
import Text from '../../../../components/text/Text';
import Color from '../../../../commons/Color';
import SearchSelect from '../../../../components/form/SearchSelect';
import { Radio } from 'antd';
import Device from '../../../../commons/Device';
import { Form } from 'antd';
const { Item } = Form;

export const StyledTitle = styled(Text)`
	font-size: 16px;
	color: ${props => (props.target ? Color.main : Color.footer)};
	margin: 0px;
`;

export const StyledDescription = styled(Text)`
	font-size: 16px;
	margin: 0px;

	@media ${Device.xs} {
		line-height: 16px;
		font-size: 12px;
		margin-bottom: 12px;
	}
`;

export const StyledText = styled(Text)`
	font-size: 12px;
	color: #86858a;
	margin: 0px;
	line-height: 16px;
`;

export const StyledOtherPointText = styled(Text)`
	font-size: 14px;
	color: ${props => (props.red ? Color.main : Color.footer)};
	margin: 0px;
	line-height: 16px;

	@media ${Device.xs} {
		font-size: 12px;
	}
`;

export const StyledRadio = styled(Radio)`
	display: flex !important;
	align-items: center;
	margin-bottom: 20px !important;
`;

export const RadioContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 20px;
	padding: 0px 40px;
	width: 100%;

	@media ${Device.xs} {
		padding: 0px;
	}
`;

export const StyledSearchSelect = styled(SearchSelect)`
	padding: 0px !important;
	width: 30%;
	margin: 0px;

	.ant-select-selection {
		border-radius: 10px;
	}

	@media ${Device.xs} {
		width: 100%;
	}
`;

export const StyledItem = styled(Item)`
	width: 100%;
	margin: 0px;
	padding: 0px;
`;

export const OtherPointItem = styled(Item)`
	margin-right: 20px !important ;
	width: 100%;

	@media ${Device.xs} {
		margin: 0px !important ;
	}
`;

export const Fields = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-start;
	margin-top: 20px;

	@media ${Device.xs} {
		flex-direction: column;
	}
`;
