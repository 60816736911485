/*----- Core -----*/
import React from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import { Form } from 'antd';

import { getHotels, resetHotelFilter } from '../../actions/searchFormActions';
import SearchForms from '../../../../components/form/SearchForms';
import SearchFormDestinations from '../../../../components/form/SearchForms/SearchFormDestinations';
import SearchFormNights from '../../../../components/form/SearchForms/SearchFormNights';
import { buildRoomsQuery } from '../../../../utilities/buildSearchQuery';
import SearchFormCurrency from '../../../../components/form/SearchForms/SearchFormCurrency';

const SearchForm = ({
	form,
	header,
	className,
	getHotels,
	onSubmit,
	resetHotelFilter
}) => {
	const preSearch = !window.location.pathname.includes('search');
	const fetchData = fields => {
		resetHotelFilter();
		getHotels({
			city_code: fields.city_code,
			date_in: fields.date_in,
			date_out: fields.date_out,
			passengers: buildRoomsQuery(fields.rooms),
			preset: fields.preset
		});
	};

	return (
		<div className={className}>
			<SearchForms
				form={form}
				header={header}
				destinations={<SearchFormDestinations form={form} md={preSearch ? 3 : 4} />}
				nights={<SearchFormNights form={form} />}
				currency={<SearchFormCurrency form={form} md={preSearch ? 3 : 4} />}
				fetchData={fetchData}
				moreRooms={true}
				onSubmit={onSubmit}
				service="hotels"
			/>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		getHotels: payload => getHotels(dispatch, payload),
		resetHotelFilter: payload => resetHotelFilter(dispatch, payload)
	};
};

export default connect(
	null,
	mapDispatchToProps
)(Form.create({ name: 'hotels_filter' })(SearchForm));
