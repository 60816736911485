/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';

/*----- Components -----*/
import ReservationPanelDates from './ReservationPanelDates';
import ReservationPanelHeader from './ReservationPanelHeader';

/*----- Styles-----*/
import { CardContainer, ItemsContainer, Button, StyledWarningText } from './styles';

const ReservationPanel = ({
	dateFrom,
	dateTo,
	textButton,
	amount,
	currency,
	textTotal,
	dateLabel,
	children,
	onClick,
	disabled = false,
	buttonLegend,
	panelTitle
}) => (
	<CardContainer>
		<ReservationPanelHeader
			panelTitle={panelTitle}
			currency={currency}
			amount={amount}
			textTotal={textTotal}
		/>

		<ItemsContainer>
			<ReservationPanelDates
				dateLabel={dateLabel}
				dateFrom={dateFrom}
				dateTo={dateTo}
			/>
			{children}
			<Button disabled={disabled} content={textButton} onClick={onClick} />
			{disabled && <StyledWarningText bold content={buttonLegend} />}
		</ItemsContainer>
	</CardContainer>
);

/*----- PropTypes -----*/
ReservationPanel.propTypes = {
	dateFrom: PropTypes.string,
	dateTo: PropTypes.string,
	textButton: PropTypes.string,
	amount: PropTypes.number,
	currency: PropTypes.string,
	textTotal: PropTypes.string,
	dateLabel: PropTypes.string,
	children: PropTypes.node,
	onClick: PropTypes.func
};

export default ReservationPanel;
