export const driver = window.localStorage;

export const addSearchQuery = query => {
	driver.setItem('search_query', JSON.stringify(query));
};

export const removeSearchQuery = () => {
	driver.removeItem('search_query');
};

export const getSearchQuery = () => {
	return JSON.parse(driver.getItem('search_query'));
};

export const addSearchQueryPackageHotel = query => {
	driver.setItem('search_query_package_hotel', JSON.stringify(query));
};

export const removeSearchQueryPackageHotel = () => {
	driver.removeItem('search_query_package_hotel');
};

export const getSearchQueryPackageHotel = () => {
	return JSON.parse(driver.getItem('search_query_package_hotel'));
};

export const addWithHotel = value => {
	return driver.setItem('with_hotel', value);
};

export const getWithHotel = () => {
	return JSON.parse(driver.getItem('with_hotel')) || false;
};
