/*----- Imports -----*/
import React from 'react';
import { connect } from 'react-redux';
import {
	StyledFooter,
	StyledRow,
	StyledCol,
	StyledLink,
	StyledLogo
} from './styles';

/*----- Assets -----*/
import logo from '../../../../assets/logoTrinusFooter.png';

/*----- Selectors -----*/
import { footerVisibleSelector } from '../../../../modules/app/AppSelectors';

/*----- Component -----*/
import SocialNetworks from './SocialNetworks';
import Contact from './Contact';

const Footer = ({ footerVisible }) => {
	return (
		footerVisible && (
			<StyledFooter>
				<StyledRow type="flex" align="middle" justify="space-around">
					<StyledCol sm={{ order: 1 }} xs={{ order: 3 }}>
						<Contact />
					</StyledCol>
					<StyledCol sm={{ order: 2 }} xs={{ order: 1 }}>
						<StyledLink to="/home">
							<StyledLogo src={logo} alt="logo" />
						</StyledLink>
					</StyledCol>
					<StyledCol sm={{ order: 3 }} xs={{ order: 2 }}>
						<SocialNetworks />
					</StyledCol>
				</StyledRow>
			</StyledFooter>
		)
	);
};

const mapStateToProps = state => {
	return {
		footerVisible: footerVisibleSelector(state)
	};
};

export default connect(mapStateToProps, null)(Footer);
