import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledServicesNavBar, StyledNavBar } from './styles';

/*----- Components -----*/
import NavButton from '../../../button/NavButton';
import Logo from './Logo';

/*----- Assets -----*/
import ExcursionsIcon from '../../../../assets/icons/services/Map';
import PacksIcon from '../../../../assets/icons/services/Suitcase';
import TransfersIcon from '../../../../assets/icons/services/Van';
import LodgingIcon from '../../../../assets/icons/services/Bed';
import { withRouter } from 'react-router';

const ServicesNavBar = ({ location: { pathname } }) => {
	const { t } = useTranslation();

	const checkSelected = url => {
		if (pathname === '/home' && url === '/transfers') {
			return true;
		}
		return pathname.toLowerCase().startsWith(url.toLowerCase());
	};

	return (
		<StyledServicesNavBar>
			<Logo absolute="true" />
			<StyledNavBar>
				<NavButton
					content={t('transfers.title')}
					destination="/transfers"
					selected={checkSelected('/transfers')}
					icon={<TransfersIcon />}
				/>
				<NavButton
					content={t('activities.title')}
					destination="/activities"
					selected={checkSelected('/activities')}
					icon={<ExcursionsIcon />}
				/>
				<NavButton
					content={t('packages.title')}
					destination="/packages"
					selected={checkSelected('/packages')}
					icon={<PacksIcon />}
				/>
				<NavButton
					content={t('hotels.title')}
					destination="/hotels"
					selected={checkSelected('/hotels')}
					icon={<LodgingIcon />}
				/>
			</StyledNavBar>
		</StyledServicesNavBar>
	);
};

export default withRouter(ServicesNavBar);
