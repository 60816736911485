/*----- Core -----*/
import React from 'react';
import styled, { css } from 'styled-components';
import Device from '../../../commons/Device';

/*----- Components -----*/
import { Row, Col } from 'antd';
import CheckoutData from '../../../modules/commons/confirm/CheckoutData';
import { LabelConfirm } from '../../../modules/commons/checkout/CheckoutDataItem';

const StyledList = styled.div`
	margin-bottom: 40px;
	@media ${Device.xs} {
		margin-bottom: 16px;
	}

	${props =>
		props.lastItem &&
		css`
			margin-bottom: 0px !important;
		`}
`;

const CheckoutConfirmList = ({ title, list, lastItem }) => {
	const getList = () => {
		return list.map((item, idx) => (
			<Col xs={24} sm={12} md={4} key={idx}>
				<CheckoutData
					label={item.label}
					value={item.value}
					lastItemData={lastItem && idx === list.length - 1}
				/>
			</Col>
		));
	};

	return (
		<StyledList lastItem={lastItem}>
			<LabelConfirm>{`${title}:`}</LabelConfirm>
			<Row type="flex">{getList()}</Row>
		</StyledList>
	);
};

export default CheckoutConfirmList;
