import styled from 'styled-components';
import Color from '../../../../commons/Color';
import Text from '../../../text/Text';
import Device from '../../../../commons/Device';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

export const StyledFooter = styled.footer`
	background-color: ${Color.footer};
	min-height: 300px;
	display: flex;
	justify-content: center;

	@media ${Device.xs} {
		height: 100vh;
		padding: 32px 20px;
	}
`;

export const StyledRow = styled(Row)`
	width: 100%;
`;

export const StyledCol = styled(Col)`
	margin-bottom: 16px;
`;

export const StyledLink = styled(Link)`
	padding-top: 20px;
	padding-bottom: 20px;
`;

export const StyledLogo = styled.img`
	width: 298px;

	@media ${Device.xs} {
		width: 235px;
	}
`;

/*----- Social Networks -----*/
export const StyledSocialNetworks = styled.div`
	display: flex;
`;

export const StyledSocialItem = styled.a`
	display: flex;
	height: 40px;
	width: 40px;
	padding: 16px;
	justify-content: center;
	align-items: center;
	margin-left: 8px;
`;

export const StyledSocialIcon = styled.img`
	width: 41px;
	height: 41px;
`;

/*----- Contact -----*/
export const StyledContact = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const StyledContactList = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const StyledContactItem = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledContactIcon = styled.img``;

export const StyledContactTitle = styled(Text)`
	font-size: 20px;
	color: white;
	font-weight: bold;
`;

export const StyledContactText = styled(Text)`
	padding: 0px;
	margin: 0px 5px;
	color: white;
`;
