import React from 'react';
import { ItemCheckoutConfirmContainer } from '../../../../../components/containers/CheckoutContainer';
import CheckoutConfirmList from '../../../../../components/list/checkoutConfirmList/CheckoutConfirmList';
import { useTranslation } from 'react-i18next';
import { getConfirmData } from '../../../../commons/confirm/confirmUtils';

const TransferInformation = ({ dataCheckout, withHotel }) => {
	const { t } = useTranslation();

	const addLodging = data => {
		data.push({
			label: t('checkout.lodging.self'),
			value: dataCheckout.in.hotel_info
		});

		return data;
	};

	const listDeparture = addLodging(getConfirmData(dataCheckout, t, 'in'));
	const listArrival = addLodging(getConfirmData(dataCheckout, t, 'out'));
	const listLodging = !withHotel
		? [
				{
					label: 'Hotel',
					value: dataCheckout.lodging.name
				}
		  ]
		: [];

	return (
		<ItemCheckoutConfirmContainer>
			<CheckoutConfirmList
				title={t('checkout.packages.arrival')}
				list={listDeparture}
				lastItem={false}
			/>
			<CheckoutConfirmList
				title={t('checkout.packages.departure')}
				list={listArrival}
				lastItem={withHotel}
			/>
			{!withHotel && (
				<CheckoutConfirmList
					title={t('moreInformation.meetingPoint.title')}
					list={listLodging}
					lastItem={true}
				/>
			)}
		</ItemCheckoutConfirmContainer>
	);
};

export default TransferInformation;
