import React, { useState } from 'react';
import Styles from '../../styles';
import { useTranslation } from 'react-i18next';
import { Form, Radio } from 'antd';
import { StyledTitle, StyledRadio } from '../styles';
import MeetingPointRadio from './MeetingPointRadio';
import OtherPointRadio from './OtherPointRadio';

const PackagesLodgingForm = ({ form, locations, dataCheckout }) => {
	const { t } = useTranslation();

	const [infoVisible, setInfoVisible] = useState(
		dataCheckout ? dataCheckout.meeting : 0
	);
	const { getFieldDecorator } = form;

	let targetMeetingPoint = infoVisible === 0;
	let targetOtherPoint = infoVisible === 1;

	return (
		<Styles>
			<Form.Item>
				{getFieldDecorator('meeting', {
					initialValue: infoVisible,
					rules: [],
					validateTrigger: 'onSubmit'
				})(
					<Radio.Group
						onChange={e => {
							setInfoVisible(e.target.value);
						}}
					>
						<StyledRadio value={0} key={0}>
							<StyledTitle
								bold
								target={targetMeetingPoint}
								content={t('checkout.meetingPassenger.meeting.title')}
							/>
						</StyledRadio>
						{targetMeetingPoint && (
							<MeetingPointRadio
								form={form}
								initialValueSelect={
									dataCheckout && !dataCheckout.meeting
										? dataCheckout.lodging.meeting_point
										: ''
								}
								locations={locations}
							/>
						)}
						<StyledRadio value={1} key={1}>
							<StyledTitle
								bold
								target={targetOtherPoint}
								content={t('checkout.meetingPassenger.other.title')}
							/>
						</StyledRadio>
						{targetOtherPoint && (
							<OtherPointRadio
								form={form}
								initialHotel={
									dataCheckout && dataCheckout.meeting
										? dataCheckout.lodging.name
										: ''
								}
								initialAddress={
									dataCheckout && dataCheckout.meeting
										? dataCheckout.lodging.address
										: ''
								}
							/>
						)}
					</Radio.Group>
				)}
			</Form.Item>
		</Styles>
	);
};

export default PackagesLodgingForm;
