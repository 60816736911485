import React from 'react';
import styled from 'styled-components';

/*----- Components -----*/
import { Switch } from 'antd';

/*----- Commons -----*/
import OpenSansBold from '../../commons/mixins/fonts/OpenSansBold';
import Color from '../../commons/Color';

const Styles = styled.div`
	padding-left: 7px;
	display: flex;
	justify-content: center;
	align-items: center;
	span {
		margin-left: 5px;
		${OpenSansBold(14, 400, Color.footer)}
	}
	.ant-switch {
		background-color: ${Color.footer};
		&::after {
			background-color: white;
		}
	}

	.label-checked {
		color: ${Color.main};
	}

	.ant-switch-checked {
		background-color: ${Color.main};
	}
`;

const DefaultSwitch = ({ label, onChange, checked, value, afterChange }) => {
	return (
		<Styles>
			<Switch
				checked={checked}
				onChange={value => {
					onChange(value);
					if (afterChange) {
						afterChange(value);
					}
				}}
				size={'small'}
				value={value}
			/>
			<span className={checked ? 'label-checked' : ''}>{label}</span>
		</Styles>
	);
};

export default DefaultSwitch;
