import React from 'react';
import { StyledDrawer, StyledLink, StyledLogo, MenuDrawerItems } from './styles';
import logo from '../../assets/logoTrinus.png';
import { useTranslation } from 'react-i18next';

const MenuDrawer = ({ onClose, visible }) => {
	const { t } = useTranslation();
	return (
		<StyledDrawer
			title={<StyledLogo src={logo} alt="logo" />}
			placement="left"
			closable={true}
			onClose={onClose}
			visible={visible}
		>
			<MenuDrawerItems>
				<StyledLink onClick={onClose} to="/home">
					{t('general.menu.init')}
				</StyledLink>
				<StyledLink onClick={onClose} to="/contact">
					{t('general.menu.contactUs')}
				</StyledLink>
				<StyledLink onClick={onClose} to="/about_us">
					{t('general.menu.aboutUs')}
				</StyledLink>
			</MenuDrawerItems>
		</StyledDrawer>
	);
};

export default MenuDrawer;
