/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import { Row, Col } from 'antd';
import Device from '../../../commons/Device';
import { LabelConfirm } from '../checkout/CheckoutDataItem';
import Text from '../../../components/text/Text';

const Styles = styled.div``;

const StyledCol = styled(Col)`
	display: flex !important;
	flex-direction: column;
	align-items: flex-start;
`;

const StyledTitle = styled(Text)`
	color: #86858a;
	font-size: 14px;

	@media ${Device.xs} {
		font-size: 12px;
	}
`;

const StyledText = styled(Text)`
	font-size: 26px;

	@media ${Device.xs} {
		font-size: 16px;
	}
`;

const StyledTextTotalPrice = styled(StyledText)`
	@media ${Device.xs} {
		font-size: 22px;
	}
`;

const CheckoutConfirmPricing = ({ taxes, sale, currency }) => {
	const { t } = useTranslation();

	return (
		<Styles>
			<LabelConfirm>{t('general.rate')}</LabelConfirm>
			<Row type="flex">
				<StyledCol xs={24} sm={12} md={6}>
					<StyledTitle content={t('checkout.totalPrice')} />
					<StyledTextTotalPrice
						bold
						content={`${currency} ${(sale + taxes).toFixed(2)}`}
					/>
				</StyledCol>
			</Row>
		</Styles>
	);
};

export default CheckoutConfirmPricing;
