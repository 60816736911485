import BASE_URL from '../BaseUrl';

import requestDecorator from '../../decorators/RequestDecorator';
import { stringify } from '../../utilities/queryString';
import i18n from 'i18next';
import { storedCurrency } from '../../utilities/utils';

const PACKAGES = 'packages';

export function* getPackages(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${PACKAGES}/search?${stringify(params)}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': `${i18n.language}`
				}
			});
		},
		params,
		cbResponse
	);
}

export function* showPackages(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(
				`${BASE_URL}/${PACKAGES}/${params}?preset=${storedCurrency}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': `${i18n.language}`
					}
				}
			);
		},
		params,
		cbResponse
	);
}

export function* reservePackages(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${PACKAGES}`, {
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': `${i18n.language}`
				}
			});
		},
		params,
		cbResponse
	);
}

export function* getPackageHotels(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${PACKAGES}/search?${stringify(params)}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': `${i18n.language}`
				}
			});
		},
		params,
		cbResponse
	);
}

export function* showPackageHotel(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${PACKAGES}/search?${stringify(params)}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': `${i18n.language}`
				}
			});
		},
		params,
		cbResponse
	);
}
