import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

/*----- Components -----*/
import { Form } from 'antd';
import { ButtonCheckoutContainer } from '../../../../../components/containers/CheckoutContainer';
import Button from '../../../../../components/button/DefaultButton';

/*----- Selectors -----*/
import { showPackageData } from '../../../selectors/showPackageSelector';

/*----- Service -----*/
import {
	loadPassengers,
	countPassengers,
	getId,
	checkoutHandleSubmit,
	passengersFromQuery,
	mixFields
} from '../../../../commons/checkout/CheckoutUtils';
import CheckoutPassengers from '../../../../commons/checkout/CheckoutPassengers';
import { getSearchQuery } from '../../../../../services/SearchService';
import {
	addCheckoutPackages,
	getCheckoutPackages
} from '../../../../../services/PackagesCheckoutService';

import PackagesArrival from '../../../../commons/checkout/PackagesForms/PackagesArrival';
import PackagesLodging from '../../../../commons/checkout/PackagesForms/PackagesLodging';

import {
	pickUpLocationFoz,
	pickUpLocationPuerto
} from '../../../../../utilities/constants';
import moment from 'moment';

const PackagesForm = ({ history, form, showPackages, withHotel }) => {
	const { t } = useTranslation();
	let id = getId(history);
	const dataCheckout = getCheckoutPackages(id);
	const searchQuery = getSearchQuery();

	const locationData =
		showPackages.city.code === 'IGU' ? pickUpLocationFoz : pickUpLocationPuerto;

	let passengersRequired = searchQuery.rooms
		? passengersFromQuery(searchQuery.rooms)
		: {
				adults_number: searchQuery.adults_number,
				children_ages: searchQuery.children_ages
		  };

	const passengers = countPassengers(passengersRequired);

	const getHotel = locationId => {
		let hotel = locationData.find(hotel => hotel.value === locationId);
		return hotel.label;
	};

	const setPassengersParsed = data => {
		let checkoutInfo = {
			observation: data.observation || '',
			passengers: loadPassengers(data, passengers),
			reservation_date_in: moment(searchQuery.date_in).format('DD/MM/YYYY'),
			meeting: data.meeting,
			lodging: {},
			...mixFields(true, data, 'mix')
		};

		if (!withHotel) {
			if (data.meeting) {
				checkoutInfo.lodging.name = data.otherPointHotel;
				checkoutInfo.lodging.address = data.otherPointAddress;
			} else {
				checkoutInfo.lodging.name = getHotel(data.meeting_point);
				checkoutInfo.lodging.meeting_point = data.meeting_point;
			}
		}

		addCheckoutPackages(id, checkoutInfo);
	};

	return (
		<Form
			onSubmit={event => {
				checkoutHandleSubmit(
					event,
					form,
					history,
					'packages',
					id,
					setPassengersParsed
				);
			}}
		>
			<PackagesArrival
				form={form}
				withHotel={withHotel}
				dataCheckout={dataCheckout}
			/>

			{!withHotel && (
				<PackagesLodging
					form={form}
					locations={locationData}
					dataCheckout={dataCheckout}
				/>
			)}

			<CheckoutPassengers
				form={form}
				passengersRequired={passengersRequired}
				dataCheckout={dataCheckout}
				countPassengers={passengers}
			/>

			<ButtonCheckoutContainer>
				<Button htmlType="submit" content={t('checkout.confirmReservation')} />
			</ButtonCheckoutContainer>
		</Form>
	);
};

const mapStateToProps = state => {
	return {
		showPackages: showPackageData(state)
	};
};

export default connect(
	mapStateToProps,
	null
)(Form.create({ name: 'checkout_packages_form' })(withRouter(PackagesForm)));
