/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';

/*----- Commons -----*/
import CardCheckoutData from '../../../../commons/checkout/CardCheckoutData';
import detailsItemsActivity from './detailsItemsActivity';
import { useTranslation } from 'react-i18next';
import { getCheckoutActivity } from '../../../../../services/ActivityCheckoutService';
import { getId } from '../../../../commons/checkout/CheckoutUtils';
import { withRouter } from 'react-router';

const CheckoutActivityData = ({ history, showActivity }) => {
	const { t } = useTranslation();

	let details = showActivity.rate.amount_details.occupancy;

	return (
		<CardCheckoutData
			image={showActivity.images[0]}
			type={t('activities.singleTitle')}
			name={showActivity.name}
			dateFrom={getCheckoutActivity(getId(history)).reservation_date}
			currency={showActivity.rate.currency}
			totalPrice={details.passenger.sale + details.passenger.iva}
			items={detailsItemsActivity(
				details.required.adults_number,
				details.required.children_ages.length
			)}
		/>
	);
};
export default withRouter(CheckoutActivityData);

/*----- PropTypes -----*/

CheckoutActivityData.propTypes = {
	history: PropTypes.object.isRequired,
	showActivity: PropTypes.object.isRequired
};
