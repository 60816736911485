import { buildFetchQuery } from '../../utilities/buildSearchQuery';

export const genericOnChange = (
	page,
	pageSize,
	history,
	query,
	fetchData,
	service,
	moreRooms
) => {
	let newQuery = {
		...query,
		page: page,
		per_page: pageSize
	};

	fetchData(buildFetchQuery(newQuery, moreRooms));

	replaceSearch(history, service, newQuery);
};

export const replaceSearch = (history, service, params) => {
	history.replace(
		{
			pathname: `/${service}/search`,
			search: `?q=${encodeURIComponent(JSON.stringify(params))}`
		},
		{ q: JSON.stringify(params) }
	);
};
