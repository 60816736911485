import React, { useEffect } from 'react';
import { connect } from 'react-redux';

/*----- Components -----*/
import SuccessResponse from './section/SuccessResponse';
import ReservationContent from '../../../commons/reservation/ReservationContent';

/*----- Actions -----*/
import { reservePackages } from '../../actions/reserveActions';

/*----- Selectors -----*/
import {
	reservePackagesIsLoading,
	reservePackagesError,
	reservePackagesSuccess
} from '../../selectors/reservePackagesSelector';

/*----- Utils -----*/
import { getCheckoutPackages } from '../../../../services/PackagesCheckoutService';
import { getId } from '../../../commons/checkout/CheckoutUtils';
import { storedCurrency } from '../../../../utilities/utils';

const ReservePackages = ({
	history,
	error,
	success,
	isLoading,
	reservePackages
}) => {
	useEffect(() => {
		let id = getId(history);
		let checkoutData = getCheckoutPackages(id);

		if (!checkoutData) {
			history.push('/packages');
			return;
		}

		let params = {
			id: id,
			date: checkoutData.reservation_date_in,
			preset: storedCurrency,
			checkout: checkoutData
		};

		reservePackages(params);
	}, [history, history.location.pathname, reservePackages]);

	return (
		<ReservationContent
			error={error}
			success={success}
			isLoading={isLoading}
			SuccessResponse={SuccessResponse}
		/>
	);
};

const mapStateToProps = state => {
	return {
		isLoading: reservePackagesIsLoading(state),
		error: reservePackagesError(state),
		success: reservePackagesSuccess(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reservePackages: payload => reservePackages(dispatch, payload)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservePackages);
