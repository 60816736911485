import React, { useState } from 'react';
import { StyledBurgerMenu, StyledOptionsItems, StyledIconMenu } from './styles';
import MenuDrawer from './MenuDrawer';
import HeaderItems from './HeaderItems';
import ServicesNavBar from '../commons/nav/Header/ServicesNavBar';
import { withRouter } from 'react-router';

const NavigationDrawer = ({ history }) => {
	const [visible, setVisible] = useState(false);
	const showServiceNavBar = () => {
		let paths = history.location.pathname.split('/');
		let currentStep = paths[paths.length - 1];
		if (
			currentStep === 'confirm' ||
			currentStep === 'checkout' ||
			currentStep === 'reservation'
		)
			return false;
		else return true;
	};
	return (
		<StyledBurgerMenu>
			<StyledOptionsItems showProfile>
				<MenuDrawer visible={visible} onClose={() => setVisible(false)} />
				<StyledIconMenu type="menu" onClick={() => setVisible(true)} />
				<HeaderItems />
			</StyledOptionsItems>
			{showServiceNavBar() && <ServicesNavBar />}
		</StyledBurgerMenu>
	);
};

export default withRouter(NavigationDrawer);
