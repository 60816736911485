import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

/*----- Utils -----*/
import { groupByRoomType } from '../HotelRooms/hotelRoomsUtils';

/*----- Actions -----*/
import { setHotelRoom } from '../../../../hotels/actions/hotelRoomActions';

/*----- Selectors -----*/
import { hotelRoom } from '../../../../hotels/selectors/hotelRoomSelector';

/*----- Components -----*/
import ServicesCarouselRoom from '../../../../../components/carousel/ServiceCarouselRoom';
import { StyledRooms, ChooseRoomTitle, RoomContainer } from './styles';
import RoomDescriptionCard from './RoomDescriptionCard';
import { getId } from '../../../checkout/CheckoutUtils';

const HotelRoomsMobile = ({
	history,
	hotelRoom,
	showData,
	setHotelRoom,
	addCheckout,
	getInformation,
	getDetailCheckout,
	service
}) => {
	const { t } = useTranslation();
	const rates = groupByRoomType(showData.rates.sort((a, b) => a.amount - b.amount));
	let dataInformation = getInformation(showData, hotelRoom.item_id);
	let id = getId(history);

	const getRooms = () => {
		return Object.keys(rates).map((rate, idx) => (
			<RoomContainer key={idx}>
				<RoomDescriptionCard
					typeRoom={rate}
					items={getDetailCheckout(dataInformation, ['nights', 'passengers'])}
				/>
				<ServicesCarouselRoom
					key={idx}
					typeRoom={rate}
					data={rates[rate]}
					onClick={room => {
						setHotelRoom(room);
						addCheckout(id, {
							item_id: room.item_id
						});
						history.push(`/${service}/${id}/checkout`);
					}}
				/>
			</RoomContainer>
		));
	};

	return (
		<StyledRooms>
			<ChooseRoomTitle
				content={t('general.chooseYourRoom')}
				size={3}
				color="secondary"
			/>
			{getRooms()}
		</StyledRooms>
	);
};

const mapStateToProps = state => {
	return {
		hotelRoom: hotelRoom(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setHotelRoom: payload => setHotelRoom(dispatch, payload)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(HotelRoomsMobile));
