import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextPrice, AmountPrice } from '../SaleCard/Price/styles';
import { StyledTitle } from '../../../components/panel/ReservationPanelHeader/styles';
import { StyledServiceRoomCard, TitleContainer } from './styles';
import { StyledDefaultButton } from '../../carousel/styles';
import ReservationPanalItemIcon from '../../panel/ReservationPanalItemIcon';
import RegimeIcon from '../../../assets/icons/checkout/reception-bell.svg';

const RoomCard = ({ currency, price, typeRoom, regime, onClick }) => {
	const { t } = useTranslation();

	return (
		<StyledServiceRoomCard>
			<TitleContainer>
				<StyledTitle
					content={`${t('general.room')} ${typeRoom}`}
					size={4}
					color="secondary"
				/>
				<ReservationPanalItemIcon icon={RegimeIcon} description={regime} />
			</TitleContainer>

			<StyledTitle content={t('checkout.totalPrice')} size={4} color="secondary" />
			<AmountPrice content={`${currency} ${price}`} />
			<TextPrice content={t('checkout.taxes')} />
			<StyledDefaultButton content={t('general.reserve')} onClick={onClick} />
		</StyledServiceRoomCard>
	);
};

export default RoomCard;
