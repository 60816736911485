import React, { useState, forwardRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import { PassengerResults, MultipleRoomResults } from './Results';
import PassengerRoom from './PassengerRoom';
import PeopleContainer from './PeopleContainer';
import MobileModal from '../../modal/MobileModal';

/*----- Commons -----*/
import ButtonWithIcon from '../../button/ButtonWithIcon';
import { isMobile } from '../../../utilities/utils';

/*----- Assets -----*/
import MinusIcon from '../../../assets/icons/minus.svg';
import PlusIcon from '../../../assets/icons/plus.svg';

/*----- Styles -----*/
import {
	Container,
	TitleContainer,
	RoomListContainer,
	ModalContent,
	StyledDefaultButton,
	StyledTitle
} from './styles';

/*----- Constants -----*/
import { maxRooms, defaultAdults } from '../../../utilities/constants';

export const initialOccupancy = {
	adults_number: defaultAdults,
	children_ages: []
};

// Debe ser inyectado por Ant en initialvalue o como value por el componente que use este input.
// value = { 0: initialOccupancy }

const RoomInput = ({ onChange, moreRooms = false, value }, ref) => {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(false);
	const [roomsCount, setRoomsCount] = useState(1);

	useEffect(() => {
		setRoomsCount(Object.keys(value).length || 1);
	}, [value]);

	const addRoom = event => {
		event.preventDefault();

		if (roomsCount === maxRooms) return;

		const updatedRoomsCount = roomsCount + 1;
		let updatedRooms = value;

		updatedRooms[roomsCount] = initialOccupancy;

		setRoomsCount(updatedRoomsCount);
		onChange(updatedRooms);
	};

	const removeRoom = event => {
		event.preventDefault();

		if (roomsCount === 1) return;

		const updatedRoomsCount = roomsCount - 1;
		let updatedRooms = value;

		// Se borra directamente la clave numérica del Object.
		delete updatedRooms[updatedRoomsCount];

		setRoomsCount(updatedRoomsCount);
		onChange(updatedRooms);
	};

	const passengerChangeHandler = (room, updatedValue) => {
		onChange({
			...value,
			[room]: updatedValue
		});
	};

	const totalPassengers = useMemo(() => {
		let result = 0;

		for (let index = 0; index < roomsCount; index++) {
			result += value[index].adults_number;
			result += value[index].children_ages.length;
		}

		return result;
	}, [value, roomsCount]);

	const SelectPassengers = () => (
		<RoomListContainer>
			{moreRooms && (
				<>
					<TitleContainer>
						<StyledTitle content={t('general.amountOfRooms')} size={5} />
					</TitleContainer>

					<div>
						<PeopleContainer title={t('general.rooms')}>
							<ButtonWithIcon icon={MinusIcon} onClick={removeRoom} />

							<span>{roomsCount}</span>

							<ButtonWithIcon icon={PlusIcon} onClick={addRoom} />
						</PeopleContainer>
					</div>
				</>
			)}

			{Object.entries(value).map(([room, occupancy]) => (
				<PassengerRoom
					visible={visible}
					moreRooms={moreRooms}
					room={parseInt(room)}
					value={occupancy}
					key={room}
					onChange={value => passengerChangeHandler(parseInt(room), value)}
				/>
			))}
		</RoomListContainer>
	);

	const ConfirmButton = () => (
		<StyledDefaultButton
			content={t('checkout.confirm')}
			onClick={event => {
				event.preventDefault();
				setVisible(!visible);
			}}
		/>
	);

	return (
		<span ref={ref}>
			{(moreRooms && (
				<MultipleRoomResults
					roomsCount={roomsCount}
					people={totalPassengers}
					onClick={() => setVisible(true)}
				/>
			)) || (
				<PassengerResults
					people={totalPassengers}
					onClick={() => setVisible(true)}
				/>
			)}

			{isMobile() && (
				<MobileModal
					visible={visible}
					onCancel={() => setVisible(false)}
					title={t('checkout.changePassengers')}
					content={
						<ModalContent>
							<SelectPassengers />
							<ConfirmButton />
						</ModalContent>
					}
				/>
			)}
			{!isMobile() && (
				<Container visible={visible}>
					<SelectPassengers />
					<ConfirmButton />
				</Container>
			)}
		</span>
	);
};

export default forwardRef(RoomInput);

/*----- PropTypes -----*/
RoomInput.propTypes = {
	value: PropTypes.object.isRequired,
	moreRooms: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired
};
