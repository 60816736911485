import BASE_URL from '../BaseUrl';

import requestDecorator from '../../decorators/RequestDecorator';
import { stringify } from '../../utilities/queryString';
import i18n from 'i18next';
import { storedCurrency } from '../../utilities/utils';

const TRANSFERS = 'transfers';

export function* getTransfers(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${TRANSFERS}/search?${stringify(params)}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': `${i18n.language}`
				}
			});
		},
		params,
		cbResponse
	);
}

export function* showTransfer(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(
				`${BASE_URL}/${TRANSFERS}/${params}?preset=${storedCurrency}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': `${i18n.language}`
					}
				}
			);
		},
		params,
		cbResponse
	);
}

export function* reserveTransfer(params, cbResponse) {
	yield requestDecorator(
		function* (params) {
			return yield fetch(`${BASE_URL}/${TRANSFERS}`, {
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': `${i18n.language}`
				}
			});
		},
		params,
		cbResponse
	);
}
