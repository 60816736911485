import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import GenericDetailContainer from '../../../../commons/detail/hotels';

/*----- Utils -----*/
import { packagesWithHotelSteps } from '../../../../../utilities/utils';
import {
	addCheckoutPackages,
	addPackageHotel,
	getCheckoutPackages
} from '../../../../../services/PackagesCheckoutService';

import { getDetailCheckoutWithHotel } from '../../checkout/section/detailsItemsPackages';

import { showPackageData } from '../../../selectors/showPackageSelector';
import {
	getId,
	parsePackageHotelRoom
} from '../../../../commons/checkout/CheckoutUtils';
import { withRouter } from 'react-router';
import { getHotelInformation } from '../../../../hotels/views/checkout/section/detailsItemsHotel';
import { setHotelRoom } from '../../../../hotels/actions/hotelRoomActions';

/*----- Selectors -----*/

const SuccessResponse = ({ history, showPackage, setHotelRoom }) => {
	const hotel = showPackage.rate.hotel;

	useEffect(() => {
		let hotelRoom = parsePackageHotelRoom(showPackage);
		setHotelRoom(hotelRoom);
		addCheckoutPackages(getId(history), { hotel: { name: hotel.name } });
		addPackageHotel(hotelRoom);
	}, [history, hotel, showPackage, setHotelRoom]);

	return (
		<GenericDetailContainer
			id={getId(history)}
			service="packages"
			showData={showPackage}
			getCheckout={getCheckoutPackages}
			active={1}
			dataStep={packagesWithHotelSteps}
			addCheckout={addCheckoutPackages}
			getInformation={getHotelInformation}
			getDetailCheckout={getDetailCheckoutWithHotel}
			panelTitle="packages.packageWithHotel"
		/>
	);
};

const mapStateToProps = state => {
	return {
		showPackage: showPackageData(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setHotelRoom: payload => setHotelRoom(dispatch, payload)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SuccessResponse));
