import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

/*----- Components -----*/
import { Form } from 'antd';
import {
	ButtonCheckoutContainer,
	ItemCheckoutContainer
} from '../../../../../components/containers/CheckoutContainer';
import ArrivalForm from '../../../../commons/checkout/ArrivalForm';
import Button from '../../../../../components/button/DefaultButton';
import Text from '../../../../../components/text/Text';

/*----- Selectors -----*/
import { transferData } from '../../../selectors/showTransferSelector';

/*----- Service -----*/
import {
	addCheckoutTransfer,
	getCheckoutTransfer
} from '../../../../../services/TransferCheckoutService';
import CheckoutPassengers from '../../../../commons/checkout/CheckoutPassengers';
import {
	loadPassengers,
	countPassengers,
	getId,
	checkoutHandleSubmit,
	mixFields
} from '../../../../commons/checkout/CheckoutUtils';
import { getSearchQuery } from '../../../../../services/SearchService';

const TransferForm = ({ history, form, transfer }) => {
	const { t } = useTranslation();
	let id = getId(history);
	const searchQuery = getSearchQuery();
	const dataCheckout = getCheckoutTransfer(id);
	let passengersRequired = transfer.rate.amount_details.occupancy.required;
	let passengers = countPassengers(passengersRequired);
	const isMix = transfer.info.kind === 'mix';

	const setPassengersParsed = data => {
		let checkoutInfo = {
			observation: data.observation || '',
			information_type: transfer.info.kind,
			passengers: loadPassengers(data, passengers),
			reservation_date_in: moment(searchQuery.date_in).format('DD/MM/YYYY'),
			...(transfer.info.kind !== 'in' && {
				reservation_date_out: moment(searchQuery.date_out).format('DD/MM/YYYY')
			}),
			...mixFields(isMix, data, transfer.info.kind)
		};

		addCheckoutTransfer(id, checkoutInfo);
	};

	return (
		<Form
			onSubmit={event => {
				checkoutHandleSubmit(
					event,
					form,
					history,
					'transfers',
					id,
					setPassengersParsed
				);
			}}
		>
			<ItemCheckoutContainer>
				<Text content={t('checkout.transfers.additional')} />

				{isMix ? (
					<>
						<ArrivalForm
							form={form}
							dataCheckout={dataCheckout && dataCheckout['in']}
							transferKind="in"
							isMix
						/>
						<ArrivalForm
							form={form}
							dataCheckout={dataCheckout && dataCheckout['out']}
							transferKind="out"
							isMix
						/>
					</>
				) : (
					<ArrivalForm
						form={form}
						dataCheckout={dataCheckout}
						transferKind={transfer.info.kind}
					/>
				)}
			</ItemCheckoutContainer>

			<CheckoutPassengers
				form={form}
				passengersRequired={passengersRequired}
				dataCheckout={dataCheckout && dataCheckout[transfer.info.kind]}
				countPassengers={passengers}
			/>

			<ButtonCheckoutContainer>
				<Button htmlType="submit" content={t('checkout.confirmReservation')} />
			</ButtonCheckoutContainer>
		</Form>
	);
};

const mapStateToProps = state => {
	return {
		transfer: transferData(state)
	};
};

export default connect(
	mapStateToProps,
	null
)(Form.create({ name: 'checkout_transfer_form' })(withRouter(TransferForm)));
