/*----- Core -----*/
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

/*----- Components -----*/
import { Form } from 'antd';
import FilterForm from '../../../../components/form/FilterForms';

/*----- Actions -----*/
import { getPackages } from '../../actions/searchFormActions';

/*----- Selectors -----*/
import {
	packagesQuery,
	packagesFilterReseted
} from '../../selectors/packagesSelector';

/*----- utilities -----*/
import { activitiesDestinations } from '../../../../utilities/constants';
import { parse } from '../../../../utilities/queryString';
import {
	buildFetchQuery,
	addFilterToQuery
} from '../../../../utilities/buildSearchQuery';
import { replaceSearch } from '../../../../components/pagination/SearchUtils';

const FilterPackage = ({
	history,
	form,
	getPackages,
	query,
	filterReseted,
	onSubmit
}) => {
	const [initialMaxPrice, setInitialMaxPrice] = useState(null);
	const [initialMinPrice, setInitialMinPrice] = useState(null);

	useEffect(() => {
		let params = parse(history.location.search, activitiesDestinations);
		if (!params) {
			history.push('/packages');
			return;
		}

		setInitialMaxPrice(params.max_price);
		setInitialMinPrice(params.min_price);
	}, [history]);

	useEffect(() => {
		if (filterReseted) {
			form.resetFields();
		}
	}, [filterReseted, form]);

	const fetchData = () => {
		let newQuery = addFilterToQuery(query, form.getFieldsValue());
		console.log(
			'CAMBIAR SI LOS PASAJEROS SE ENVIAN DE DISTINTA MANERA CUANDO ES CON O SIN HOTEL'
		);
		getPackages(buildFetchQuery(newQuery, true));
		replaceSearch(history, 'packages', newQuery);

		if (onSubmit) onSubmit();
	};

	return (
		<FilterForm
			form={form}
			fetchData={fetchData}
			disabled={filterReseted}
			defaultValues={{
				min_price: initialMinPrice,
				max_price: initialMaxPrice
			}}
		/>
	);
};

const mapStateToProps = state => {
	return {
		query: packagesQuery(state),
		filterReseted: packagesFilterReseted(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPackages: payload => getPackages(dispatch, payload)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create({ name: 'package_filter' })(withRouter(FilterPackage)));
