import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import { StyledInput, StyledTimePicker } from '../../../../components/form/styles';
import { StyledCol } from '../styles';

const KindBasedFields = ({
	form,
	isAirport,
	dataCheckout,
	fieldType = '',
	transferKind,
	isMix
}) => {
	const { t } = useTranslation();
	const { getFieldDecorator } = form;
	const mixPrefix = isMix ? `${transferKind}_` : '';
	let flyCodeInitialValue = '';
	let businessInitialValue = '';
	let timeArrivalInitialValue = '';
	let timeDepartureInitialValue = '';

	if (dataCheckout && !dataCheckout.is_airport) {
		if (fieldType) {
			if (dataCheckout[fieldType]) {
				flyCodeInitialValue = dataCheckout[fieldType].fly_code;
				businessInitialValue = dataCheckout[fieldType].company;
				timeArrivalInitialValue = dataCheckout[fieldType].arrival_time;
				timeDepartureInitialValue = dataCheckout[fieldType].departure_time;
			}
		} else {
			businessInitialValue = dataCheckout.company;
			timeArrivalInitialValue = dataCheckout.arrival_time;
			timeDepartureInitialValue = dataCheckout.departure_time;
			flyCodeInitialValue = dataCheckout.fly_code;
		}
	}

	return (
		<>
			<StyledCol xs={24} sm={12} md={5}>
				<Form.Item
					label={t(`checkout.${isAirport ? 'flight.airline' : 'business'}.title`)}
				>
					{getFieldDecorator(`${mixPrefix}business`, {
						rules: [
							{
								required: true,
								whitespace: true,
								message: t(
									`checkout.${isAirport ? 'flight.airline' : 'business'}.error`
								)
							}
						],
						initialValue: businessInitialValue
					})(<StyledInput type="text" placeholder={''} withoutLabel />)}
				</Form.Item>
			</StyledCol>
			{isAirport && (
				<>
					<StyledCol xs={24} sm={12} md={5}>
						<Form.Item label={t('checkout.flight.flycode.title')}>
							{getFieldDecorator(`${mixPrefix}fly_code`, {
								rules: [
									{
										required: isAirport,
										whitespace: isAirport,
										message: t('checkout.flight.flycode.error')
									}
								],
								initialValue: flyCodeInitialValue
							})(<StyledInput type="text" placeholder={'1234'} withoutLabel />)}
						</Form.Item>
					</StyledCol>
					<StyledCol xs={24} sm={12} md={5}>
						<Form.Item label={t('checkout.flight.time.departure')}>
							{getFieldDecorator(`${mixPrefix}time_departure`, {
								rules: [
									{
										required: true,
										message: t('checkout.flight.time.error')
									}
								],
								initialValue: timeDepartureInitialValue
							})(<StyledTimePicker placeholder={'00:00'} withoutLabel />)}
						</Form.Item>
					</StyledCol>
				</>
			)}
			<StyledCol xs={24} sm={12} md={5}>
				<Form.Item label={t('checkout.flight.time.arrival')}>
					{getFieldDecorator(`${mixPrefix}time_arrival`, {
						rules: [
							{
								required: true,
								message: t('checkout.flight.time.error')
							}
						],
						initialValue: timeArrivalInitialValue
					})(<StyledTimePicker placeholder={'00:00'} withoutLabel />)}
				</Form.Item>
			</StyledCol>
		</>
	);
};

export default KindBasedFields;
