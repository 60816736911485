import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

/*----- Components -----*/
import { Form } from 'antd';
import {
	ButtonCheckoutContainer,
	ItemCheckoutContainer
} from '../../../../../components/containers/CheckoutContainer';
import Button from '../../../../../components/button/DefaultButton';

/*----- Selectors -----*/
import { showActivityData } from '../../../selectors/showActivitySelector';

/*----- Service -----*/
import {
	addCheckoutActivity,
	getCheckoutActivity
} from '../../../../../services/ActivityCheckoutService';

import {
	loadPassengers,
	countPassengers,
	getId,
	checkoutHandleSubmit
} from '../../../../commons/checkout/CheckoutUtils';

import CheckoutPassengers from '../../../../commons/checkout/CheckoutPassengers';
import PickUpLocationForm from '../../../../commons/checkout/PickUpLocationForm';
import Text from '../../../../../components/text/Text';

import {
	pickUpLocationFoz,
	pickUpLocationPuerto
} from '../../../../../utilities/constants';
import { getSearchQuery } from '../../../../../services/SearchService';

const ActivityForm = ({ history, form, showActivity }) => {
	const { t } = useTranslation();
	const cityCode = getSearchQuery().city_code;

	const locationData = cityCode === 'IGU' ? pickUpLocationFoz : pickUpLocationPuerto;

	let id = getId(history);
	const dataCheckout = getCheckoutActivity(id);

	let passengersRequired = showActivity.rate.amount_details.occupancy.required;

	const passengers = countPassengers(passengersRequired);

	const getHotel = locationId => {
		let hotel = locationData.find(hotel => hotel.value === locationId);
		return hotel.label;
	};

	const setPassengersParsed = data => {
		let checkoutInfo = {
			passengers: [],
			observation: data.observation || '',
			pickUpLocation: data.pickUpLocation,
			hotel: getHotel(data.pickUpLocation)
		};

		checkoutInfo.passengers = loadPassengers(data, passengers);

		addCheckoutActivity(id, checkoutInfo);
	};

	return (
		<Form
			onSubmit={event => {
				checkoutHandleSubmit(
					event,
					form,
					history,
					'activities',
					id,
					setPassengersParsed
				);
			}}
		>
			<ItemCheckoutContainer>
				<Text content={t('checkout.activities.additional')} />
				<PickUpLocationForm
					form={form}
					initialValue={dataCheckout.pickUpLocation}
					locations={locationData}
				/>
			</ItemCheckoutContainer>

			<CheckoutPassengers
				form={form}
				passengersRequired={passengersRequired}
				dataCheckout={dataCheckout}
				countPassengers={passengers}
			/>

			<ButtonCheckoutContainer>
				<Button htmlType="submit" content={t('checkout.confirmReservation')} />
			</ButtonCheckoutContainer>
		</Form>
	);
};

const mapStateToProps = state => {
	return {
		showActivity: showActivityData(state)
	};
};

export default connect(
	mapStateToProps,
	null
)(Form.create({ name: 'checkout_activity_form' })(withRouter(ActivityForm)));
