import styled, { css } from 'styled-components';
import Input from './Input';
import Color from '../../commons/Color';
import Device from '../../commons/Device';
import Checkbox from './CustomCheckbox';
import Slider from './Slider';
import DatePicker from '../date-picker/DatePicker';
import TimePicker from './TimePicker';
import TextArea from './TextArea';

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 16px 0px;
	padding: 0px 8px;
	min-width: 150px;
	border-radius: 10px;

	.ant-input {
		border-radius: ${props => props.borderRadius};
		font-size: ${props => props.fontSizePlaceHolder || '16px'};
	}

	.ant-select-selection:active,
	.ant-select-selection:focus,
	.ant-select-selection:hover,
	.ant-time-picker-input:focus,
	.ant-time-picker-input:hover,
	.ant-calendar-picker-input:focus,
	.ant-calendar-picker-input:hover,
	.ant-input:hover,
	.ant-input:focus,
	.ant-select-selection--single:hover,
	.ant-select-selection--single:focus,
	.ant-checkbox-checked .ant-checkbox-inner,
	.ant-checkbox-wrapper:focus,
	.ant-checkbox-checked::after {
		border-color: ${Color.main} !important;
		box-shadow: 0px 0px 4px 0px ${Color.main} !important;
	}

	.ant-input-disabled.ant-input {
		border-color: ${Color.text} !important;
	}

	.ant-time-picker {
		width: 100%;
	}

	.input-radius .ant-input-lg {
		border-radius: 50px;
		border-color: ${Color.main};
	}

	.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
		border-color: ${Color.main};
	}

	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: ${Color.main};
	}

	.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
		background-color: ${Color.text};
		border-color: ${Color.text} !important;
	}

	.ant-checkbox-wrapper + .ant-checkbox-wrapper {
		margin-left: 0px;
	}

	.ant-checkbox-group {
		display: flex;
		flex-direction: column;
	}

	.ant-checkbox-wrapper:hover .ant-checkbox-inner,
	.ant-checkbox:hover .ant-checkbox-inner,
	.ant-checkbox-input:focus + .ant-checkbox-inner {
		border-color: ${Color.main};
	}

	.ant-checkbox-wrapper-checked span {
		font-weight: 700;
		color: ${Color.main};
	}
	.ant-checkbox-wrapper-disabled span {
		color: ${Color.text};
	}

	.ant-checkbox-checked .ant-checkbox-inner,
	.ant-checkbox-wrapper:focus,
	.ant-checkbox-checked::after {
		box-shadow: none !important;
	}

	.ant-slider {
		margin: 0px 6px 6px 6px;
		.ant-slider-track {
			background-color: ${Color.main};
		}
		&:hover {
			.ant-slider-track {
				background-color: ${Color.main};
			}
		}
	}

	.ant-slider-handle {
		border: solid 2px ${Color.main};
	}

	.ant-slider-handle.ant-tooltip-open {
		border-color: ${Color.main};
	}

	@media ${Device.xs} {
		min-width: initial;
	}
`;

export const StyledInput = styled(Input)`
	margin: 8px 0px 0px;
	padding: 0px;
	.ant-input {
		border-radius: 10px;
	}
`;

export const StyledInputTwo = styled(StyledInput)`
	padding-right: ${props => (props.left ? '4px' : '0px')};
	padding-left: ${props => (props.left ? '0px' : '4px')};
	min-width: 100px !important;
	@media ${Device.xs} {
		padding: 0px;
	}
`;

export const StyledRoundedInput = styled(StyledInput)`
	padding: 0px 8px;
	margin: 8px 0px 16px 0px;
	.ant-input {
		border-radius: 23px;
		border-color: ${Color.main} !important;
		border-width: 1.7px !important;
	}
	svg {
		width: 18px;
		height: 18px;
	}

	@media ${Device.xs} {
		margin-top: 0px;
		padding: 0px;
	}
`;

export const StyledCheckboxGroup = styled(Checkbox)`
	margin: 8px 0px 0px 0px;
	.custom-label {
		font-size: 14px;
		color: ${Color.footer};
		margin-bottom: 0px;
	}

	.ant-checkbox-wrapper {
		padding: 2px 0px;
	}

	@media ${Device.xs} {
		padding: 0px;
		.ant-checkbox-wrapper {
			font-size: 17px;
		}
		.custom-label {
			font-size: 16px;
		}
	}
`;

export const StyledSlider = styled(Slider)`
	margin-top: 8px;

	.custom-label {
		font-size: 14px;
		color: ${Color.footer};
		margin-bottom: 0px;
	}

	@media ${Device.xs} {
		padding: 0px;
		.custom-label {
			font-size: 16px;
		}
	}
`;

export const StyledGenericInput = css`
	margin: 8px 0px 0px;
	padding: 0px;
	.ant-input {
		border-radius: 10px;
	}
`;

export const StyledDatePicker = styled(DatePicker)`
	${StyledGenericInput};
`;

export const StyledTimePicker = styled(TimePicker)`
	${StyledGenericInput};
	.ant-time-picker-input {
		border-radius: 10px;
	}
`;

export const StyledTextArea = styled(TextArea)`
	${StyledGenericInput};
`;
