/*----- Core -----*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

/*----- Components -----*/
import PageUnderConstruction from '../../components/PageUnderConstruction';
import ScrollToTop from '../../components/commons/ScrollToTop';

/*----- Nav Components -----*/
import Header from '../../components/commons/nav/Header';
import Footer from '../../components/commons/nav/Footer';

/*----- Modules -----*/
import Home from '../home/Home';
import Hotels from '../hotels/views/index';
import Transfers from '../tranfers/views/index';
import Contact from '../contact/Contact';
import AboutUs from '../about_us/AboutUs';
import Activities from '../activities/views/index';
import Packages from '../packages/views/index';
import Hotel from '../hotels/views/detail';
import CheckoutHotel from '../hotels/views/checkout';
import CheckoutConfirm from '../hotels/views/confirm';
import CheckoutTransferConfirm from '../tranfers/views/confirm';

import ReservationHotel from '../hotels/views/reservation';
import ReservationTransfer from '../tranfers/views/reservation';
import ReservationActivity from '../activities/views/reservation';
import ReservationPackage from '../packages/views/reservation';

import ActivityDetail from '../activities/views/detail';
import PackageDetail from '../packages/views/detail';
import CheckoutActivity from '../activities/views/checkout';
import CheckoutActivityConfirm from '../activities/views/confirm';

import CheckoutTransfer from '../tranfers/views/checkout';
import SearchHotel from '../hotels/views/search';
import SearchTransfers from '../tranfers/views/search';

import SearchActivities from '../activities/views/search';
import CheckoutPackages from '../packages/views/checkout';
import CheckoutPackageConfirm from '../packages/views/confirm';
import SearchPackages from '../packages/views/search';
import DetailsPackageHotel from '../packages/views/detail/hotels';

import NavigationDrawer from '../../components/navigationDrawer';
import { isMobile } from '../../utilities/utils';

const ViewsContainer = () => (
	<>
		{isMobile() ? <NavigationDrawer /> : <Header />}
		<ScrollToTop>
			<Switch>
				<Route exact path={'/'} render={() => <Redirect to="/home" />} />
				<Route exact path="/home" component={Home} />
				<Route exact path="/transfers" component={Transfers} />
				<Route exact path="/hotels" component={Hotels} />
				<Route exact path="/contact" component={Contact} />
				<Route exact path="/activities" component={Activities} />
				<Route exact path="/about_us" component={AboutUs} />
				<Route exact path="/packages" component={Packages} />
				<Route exact path="/specials" render={() => <PageUnderConstruction />} />

				<Route exact path="/hotels/search" component={SearchHotel} />
				<Route exact path="/hotels/:hotelId" component={Hotel} />
				<Route
					exact
					path="/hotels/:rateId/checkout/confirm"
					component={CheckoutConfirm}
				/>
				<Route
					exact
					path="/hotels/:rateId/reservation"
					component={ReservationHotel}
				/>

				<Route exact path="/transfers/search" component={SearchTransfers} />
				<Route
					exact
					path="/transfers/:transferId/checkout"
					component={CheckoutTransfer}
				/>
				<Route
					exact
					path="/transfers/:rateId/checkout/confirm"
					component={CheckoutTransferConfirm}
				/>
				<Route
					exact
					path="/transfers/:rateId/reservation"
					component={ReservationTransfer}
				/>

				<Route exact path="/activities/search" component={SearchActivities} />
				<Route exact path="/activities/:activityId" component={ActivityDetail} />
				<Route
					exact
					path="/activities/:rateId/reservation"
					component={ReservationActivity}
				/>
				<Route
					exact
					path="/activities/:activityId/checkout"
					component={CheckoutActivity}
				/>
				<Route
					exact
					path="/activities/:activityId/checkout/confirm"
					component={CheckoutActivityConfirm}
				/>

				<Route exact path="/packages/search" component={SearchPackages} />
				<Route exact path="/packages/:packageId" component={PackageDetail} />
				<Route exact path="/hotels/:rateId/checkout" component={CheckoutHotel} />
				<Route
					exact
					path="/packages/:packageId/checkout"
					component={CheckoutPackages}
				/>
				<Route
					exact
					path="/packages/:packageId/reservation"
					component={ReservationPackage}
				/>
				<Route
					exact
					path="/packages/:packageId/hotels/:hotelId"
					component={DetailsPackageHotel}
				/>
				<Route
					exact
					path="/packages/:rateId/checkout/confirm"
					component={CheckoutPackageConfirm}
				/>
			</Switch>
		</ScrollToTop>
		<Footer />
	</>
);
export default ViewsContainer;
