/*----- Core -----*/
import React from 'react';
import styled, { css } from 'styled-components';

/*----- Commons -----*/
import Color from '../../../commons/Color';
import Device from '../../../commons/Device';

const StyledDataItem = styled.div`
	padding: 10px 30px;

	@media ${Device.xs} {
		padding: 0px 15px;
	}

	${props =>
		props.isConfirm &&
		css`
			padding: 0px !important;
		`}
`;

const Label = styled.p`
	font-weight: 700;
	font-size: 14px;
	color: ${Color.footer};

	@media ${Device.xs} {
		font-size: 16px;
		margin-bottom: 10px;
	}
`;

export const LabelConfirm = styled(Label)`
	font-size: 20px;
`;

export const DataTitle = styled.p`
	font-size: 20px;
	font-weight: 700;
	color: ${Color.footer};
	text-transform: capitalize;
	margin: 0px;
	@media ${Device.xs} {
		font-size: 18px;
	}
`;

export const DataDescription = styled.p`
	font-size: 16px;
	font-weight: 400;
	color: ${Color.text};
	margin: 0px;
	@media ${Device.xs} {
		font-size: 16px;
	}
`;

export const DataPrice = styled.p`
	font-size: 35px;
	font-weight: 400;
	color: ${Color.footer};
	margin: 0px;
	line-height: 32px;
	@media ${Device.xs} {
		font-size: 22px;
	}
`;

const CheckoutDataItem = ({ label, children, isConfirm = false }) => (
	<StyledDataItem isConfirm={isConfirm}>
		<Label>{label}</Label>
		{children}
	</StyledDataItem>
);

export default CheckoutDataItem;
