import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { StyledCol } from './styles';
import { Form } from 'antd';
import { parse } from '../../../utilities/queryString';
import { activitiesDestinations } from '../../../utilities/constants';
import { withRouter } from 'react-router';
import NightInput from '../NightInput';

const { Item } = Form;

const SearchFormNights = ({ history, form, xs = 12, md = 2 }) => {
	const { getFieldDecorator } = form;

	const [initialNights, setInitialNights] = useState(1);

	useEffect(() => {
		let params = parse(history.location.search, activitiesDestinations);

		if (!params) {
			return;
		}

		let date_out = moment(params.date_out);
		let date_in = moment(params.date_in);

		setInitialNights(date_out.diff(date_in, 'days'));
	}, [history.location.search]);

	const onAfterChange = () => {
		if (form.getFieldValue('nights') > 400) form.setFieldsValue({ nights: 400 });

		const fields = form.getFieldsValue();
		let dateIn = fields.date_in.clone();
		form.setFieldsValue({ date_out: dateIn.add(fields.nights, 'days') });
	};

	return (
		<StyledCol xs={xs} md={md}>
			<Item>
				{getFieldDecorator('nights', {
					rules: [{ required: true }],
					validateTrigger: ['onSubmit'],
					initialValue: initialNights
				})(
					<NightInput
						onAfterChange={onAfterChange}
						disabled={
							!form.getFieldValue('date_in') || !form.getFieldValue('date_out')
						}
					/>
				)}
			</Item>
		</StyledCol>
	);
};

export default withRouter(SearchFormNights);
