import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CardCheckoutData from '../../../../commons/checkout/CardCheckoutData';

const CheckoutTransferData = ({
	type,
	title,
	totalPrice,
	currency,
	date_in,
	date_out
}) => {
	const { t } = useTranslation();

	return (
		<CardCheckoutData
			type={t('transfers.typeOfTransfer')}
			name={type}
			dateFrom={date_in}
			dateTo={date_out}
			currency={currency}
			totalPrice={totalPrice}
			details={title}
		/>
	);
};

export default CheckoutTransferData;

/*----- PropTypes -----*/
CheckoutTransferData.propTypes = {
	value: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	totalPrice: PropTypes.number.isRequired,
	dateFrom: PropTypes.string.isRequired,
	dateTo: PropTypes.string,
	currency: PropTypes.string.isRequired
};
