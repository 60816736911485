/*----- Core -----*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

/*----- Antd -----*/
import { Modal } from 'antd';

/*----- Actions -----*/
import { toggleAlert } from '../../modules/app/AppActions';

/*----- Selectors -----*/
import {
	alertVisibleSelector,
	nameConsultSelector
} from '../../modules/app/AppSelectors';

/* Assets */
import ImageBackground from '../../assets/images/modalConfirmation/background-modal.svg';
import ImageLogo from '../../assets/Logo/3.png';
import ExcursionsIcon from '../../assets/icons/services/Map';
import PacksIcon from '../../assets/icons/services/Suitcase';
import TransfersIcon from '../../assets/icons/services/Van';
import LodgingIcon from '../../assets/icons/services/Bed';

const Styles = styled.div`
	height: 70vh;
	.first-row {
		display: flex;
		height: 70%;
		.column-image {
			background-image: url('${ImageBackground}');
			background-repeat: no-repeat;
		}
		.column-text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			h1 {
				font-family: 'Montserrat', sans-serif;
				font-weight: 900;
				color: #ff0000;
				margin-left: 2%;
			}
			h2 {
				font-family: 'Montserrat', sans-serif;
				color: #b5b7b9;
				margin-left: 2%;
			}
		}
	}
	.second-row {
		height: 30%;
		display: flex;
		flex-direction: column;
		.title {
			display: flex;
			justify-content: center;
			height: 30%;
			.content-line {
				width: 20%;
				display: flex;
				align-items: center;
				.line-left {
					border-bottom: 1.4px solid #ff0000;
					width: 100%;
				}
				.line-right {
					border-bottom: 1.4px solid #ff0000;
					width: 100%;
				}
			}
			.content-text {
				width: 50%;
				display: flex;
				position: relative;
				.content-image {
					width: 15%;
					margin: 0 10px;
				}
				.text {
					display: flex;
					align-items: center;
					h1 {
						color: #8e8e8e;
						height: fit-content;
						font-size: 1.3rem;
					}
				}
			}
		}
		.content-items {
			height: 70%;
			display: flex;
			align-items: center;
			justify-items: center;
			.item {
				display: flex;
				justify-content: center;
				align-items: center;
				background: white;
				padding: 0;
				margin: 0px 5px;
				border-radius: 5px;
				box-shadow: 1px 1px rgba(0, 0, 0, 0.1);
				.icon {
					width: 20%;
					height: 20%;
				}
				p {
					margin: 0;
					height: max-content;
					color: gray;
				}
			}
		}
	}
	svg {
		width: 40px;
		fill: gray;
		transition: all 0.5s;
		transform: scale(0.7);
	}
`;
const ConsultConfirmationModal = ({
	history,
	alertVisible,
	toggleAlert,
	nameConsult
}) => {
	const { t } = useTranslation();

	function redirect(i) {
		toggleAlert(false);
		switch (i) {
			case 1:
				history.push('/transfers');
				break;
			case 2:
				history.push('/activies');
				break;
			case 3:
				history.push('/packages');
				break;
			case 4:
				history.push('/hotels');
				break;
			default:
				break;
		}
	}
	return (
		<Modal
			footer={null}
			visible={alertVisible}
			onCancel={toggleAlert}
			className="generic-modal"
		>
			<Styles>
				<div className="first-row">
					<Col md={6} className="column-image"></Col>
					<Col md={6} className="column-text">
						<h1>{nameConsult}</h1>
						<h2>{t('contact.modal.successText')}</h2>
					</Col>
				</div>
				<div className="second-row">
					<div className="title">
						<div className="content-line">
							<div className="line-left"></div>
						</div>
						<div className="content-text">
							<div className="content-image">
								<Image src={ImageLogo} className="img-fluid"></Image>
							</div>
							<div className="text">
								<h1>{t('contact.modal.continue')}</h1>
							</div>
						</div>
						<div className="content-line">
							<div className="line-right"></div>
						</div>
					</div>
					<div className="content-items">
						<Col className="item" onClick={() => redirect(1)}>
							<div className="icon">{TransfersIcon()}</div>
							<p>{t('transfers.title')}</p>
						</Col>
						<Col className="item" onClick={() => redirect(2)}>
							<div className="icon">{ExcursionsIcon()}</div>
							<p>{t('activities.title')}</p>
						</Col>
						<Col className="item" onClick={() => redirect(3)}>
							<div className="icon">{PacksIcon()}</div>
							<p>{t('packages.title')}</p>
						</Col>
						<Col className="item" onClick={() => redirect(4)}>
							<div className="icon">{LodgingIcon()}</div>
							<p>{t('hotels.title')}</p>
						</Col>
					</div>
				</div>
			</Styles>
		</Modal>
	);
};
function mapStateToProps(state) {
	return {
		alertVisible: alertVisibleSelector(state),
		nameConsult: nameConsultSelector(state)
	};
}
function mapDispatchToProps(dispatch) {
	return {
		toggleAlert: state => toggleAlert(dispatch, state)
	};
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ConsultConfirmationModal)
);

/*----- PropTypes -----*/
// ConsultConfirmationModal.propTypes = {
// 	toggleModal: PropTypes.func.isRequired,
// 	modalVisible: PropTypes.bool.isRequired
// }
